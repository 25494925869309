








import { Component, Vue, Prop } from 'vue-property-decorator';
import CPrgTreeTable from "@/modules/budget/alteration/form/tabs/components/utochPlan/tables/CPrgTreeTable.vue";
import CGuTreeTable from "@/modules/budget/alteration/form/tabs/components/utochPlan/tables/CGuTreeTable.vue";
import CAbpGuTreeTable from "@/modules/budget/alteration/form/tabs/components/utochPlan/tables/CAbpGuTreeTable.vue";

@Component({
    components: {
        'c-prg-tree-table': CPrgTreeTable,
        'c-gu-tree-table': CGuTreeTable,
        'c-abp-gu-tree-table': CAbpGuTreeTable
    }
})
export default class UtochExpenses extends Vue {
    @Prop({
        required: false,
        default: false
    })
    TabType!: boolean; //  True::Obligations; False::Payment

    @Prop({
        required: true,
        default: 0
    })
    tableLevel!: number; //  0 GU, 1 ABP, 2 UF

    @Prop({
        required: true,
        default: []
    })
    tableData!: any[]


    treeData:any = null;
    private tree(value: any[]) {
        this.treeData = value
    }

}
