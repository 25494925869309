<template>
  <div>
    <div>
      <div class="actions-tab in-tab">
          <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                  <div class="btn-download">
                      <div>
                  <span class="left">
                      <i class="icon icon-download"></i> Скачать
                  </span>
                          <i class="icon icon-keyboard"></i>
                      </div>
                  </div>
              </template>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && !activeTab" @click="downloadApplication(9)">Приложение 25 - Справка о внесении изменений в СПП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && !activeTab" @click="downloadApplication(10)">Приложение 26 - Справка о внесении изменений в СПП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(2)">Приложение 27 - Заявка на изменение ИПФ ГУ по обязательствам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(1)">Приложение 28 - Заявка на изменение ИПФ ГУ по платежам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'abp'" @click="downloadApplication(5)">Приложение 29 - Заявка на изменение ПФ по обязательствам АБП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'abp'" @click="downloadApplication(6)">Приложение 30 - Заявка на изменение ПФ по платежам АБП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && accessLevel.position === 'abp' || selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(3)">Приложение 34 - Справка о внесении изменений в ИПФ по обязательствам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.001' && accessLevel.position === 'abp' || selectedAlteration && accessLevel.modules === '005.002.002' && accessLevel.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(4)">Приложение 36 - Справка о внесении изменений в ИПФ по платежам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && activeTab === 'uf'" @click="downloadApplication(7)">Приложение 37 - Справка о внесении изменений в СПФ по платежам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevel.modules === '005.002.002' && activeTab === 'uf'" @click="downloadApplication(8)">Приложение 39 - Справка о внесении изменений в СПФ по обязательствам</b-dropdown-item>
          </b-dropdown>

      </div>
      <div class="filter-container">
        <div class="left-content">
          <b-dropdown class="filter-dropdown" variant="default" ref="drop">
            <template #button-content>
              <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                class="icon icon-keyboard"></i></span>
            </template>
            <div>
              <div class="top-content">
                <span>Параметры фильтра</span>
                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
              </div>
              <!---------------------Дата------------------------->
              <div class="filter-block">
                <b-form-group label="Дата">
                  <date-picker
                      placeholder="Выберите дату"
                      v-model="currDate"
                      format = "DD/MM/YYYY"
                      type="date"
                      disabled
                      style="width: 400px !important; max-width: unset !important;"
                  />
                </b-form-group>
              </div>
              <!---------------------ГУ------------------------->
              <div class="filter-block" v-if="currentAlteration.gu">
                <b-form-group label="ГУ">
                  <multiselect
                      v-model="currGu"
                      label="localText"
                      :options="guBase"
                      ref="filterGu"
                      disabled
                  >
                  </multiselect>
                </b-form-group>
              </div>

              <!---------------------Вид заявки------------------------->
              <div class="filter-block">
                <b-form-group label="Вид заявки">
                  <multiselect
                      v-model="selectedAlteration"
                      :options="listAlteration"
                      label="name_ru"
                      disabled
                      ref="refAlteration"
                  >
                  </multiselect>
                </b-form-group>
              </div>
            </div>
          </b-dropdown>
        </div>
        <div class="filter-actions">
          <template>
            <b-button variant="secondary" @click="onBack">Назад к списку</b-button>
            <b-button variant="success" v-if="monthArr.length > 0" @click="onSave">Сохранить</b-button>
            <b-button v-if="operationCode.length > 0" v-show="agrEnabled"
                      variant="success"
                      :disabled="butBlock > 0"
                      @click="agreementEvent">Действия</b-button>
          </template>
        </div>
      </div>
    </div>
    <!--  хлебные крошки   -->
    <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="currDate" @click="openFilterByRef('dateRef')">
                {{ refDate }}
            </span>
      <span class="item-breadcrumb" v-if="currentAlteration.gu" @click="openFilterByRef('filterGu')">
                {{ currGu.code + ' - ' + currGu.name_ru.toString() }}
            </span>
      <span class="item-breadcrumb" v-if="selectedAlteration" @click="openFilterByRef('refAlteration')">
                Вид заявки - {{ selectedAlteration.name_ru }}
            </span>
    </div>
    <!--  Tab-ы обязательства\платежи   -->
    <div v-if="reqType">
      <div>
        <b-tabs class="tabs-in-tabs">
          <b-tab>
            <template #title>
              <button class="icon-btn" @click="toggleShowObligation">
                <i class="icon icon-keyboard icon-rotate-270" v-if="showObligation"></i>
                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
              </button>
              <span class="ml-2">
                                Обязательства
                        </span>
              <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 21px;"
                 @click="duplicatedData(0)">   Дублировать в платежи   </a>
              <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 320px;"
                 @click="addItem()">   Добавить +   </a>
              <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 175px;"
                 @click="importSpf(1)">  Импорт обязательств  </a>
            </template>
            <div class="top-table-line"></div>
            <c-spf-tree-table v-if="showObligation" :table-data="formObligation" :editedMonths="monthArr" @chgData ="chgDataObl" :accessLevel="accessLevel" :uSumVisible="uSumVisible" :tableType="tableType" :flc2="flc2" :flc4="flc4" @agrBtn="agrBtn"/>
          </b-tab>
        </b-tabs>
      </div>
      <div>
        <b-tabs class="tabs-in-tabs">
          <b-tab>
            <template #title align="left">
              <button class="icon-btn" @click="toggleShowPayment">
                <i class="icon icon-keyboard icon-rotate-270" v-if="showPayment"></i>
                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
              </button>
              <span class="ml-2">
                            Платежи
                        </span>
              <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 2; top: 15px; right: 20px;"
                 @click="duplicatedData(1)">Дублировать в обязательства</a>
              <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 213px;"
                 @click="importSpf(2)">  Импорт Платежей  </a>
            </template>
            <div class="top-table-line"></div>
            <c-spf-tree-table v-if="showPayment" :table-data="formPayment" :editedMonths="monthArr" @chgData ="chgDataPay" :accessLevel="accessLevel" :uSumVisible="uSumVisible" :tableType="tableType" :flc2="flc2" :flc4="flc4" @agrBtn="agrBtn"/>
          </b-tab>
        </b-tabs>
      </div>
      <c-spf-add-modal :show="showAddItemModal" :dataTable="formObligation" :abpProp="currAbp" @shown="showAddItemModal = false" @addItemClk="addedItems"/>
      <spf-import-from-file-modal
          :cur-abp="currAbp"
          :cur-gu="currGu"
          :cur-region="currRegion"
          :cur-month="currMonth"
          @shown="showImportFileModal = false"
          :show="showImportFileModal"
          @importTableObligations="imortObligations"
          @importTablePayments="imortPayments"
          :table-type="importTypeTable"
          :request-id="currentAlteration.id"
      ></spf-import-from-file-modal>
    </div>
    <div v-else>
      <a v-if="monthArr.length > 0 || newRequest" href="#" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 21px;" @click="addItemIncome()">   Добавить +   </a>
      <!--        компонент по поступлениям       -->
      <c-inc-tree-table :table-data="formIncome" :editedMonths="monthArr" @chgData="chgDataIncome" :accessLevel="accessLevel"/>
      <c-inc-add-modal :show="showIncomAddItemModal" :dataTable="formIncome"  @shown="showIncomAddItemModal = false" @addItemClk="addedIncomeIntems"/>
    </div>
    <loading
        :active="loading"
        is-full-screen
        spinner="bar-fade-scale"
        color="#6495ED"
    />
    <c-budg-agr-modal ref="refAgrModal" :agrBtnLst="agrBtnLst" @agrClk="agrClick"></c-budg-agr-modal>
    <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import VueElementLoading from "vue-element-loading";
import Multiselect from 'vue-multiselect';
import CSpfTreeTable from "./SpfTreeTable";
import CSpfAddItemModal from "./CSpfAddItemModal";
import CIncomeAddItemModal from "./CIncomeAddItemModal";
import CIncomeTreeTable from "./IncomeTreeTable";
import store from "@/services/store";
import {makeToast} from "@/modules/budget/bip/bip-types";
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal";
import CBudgetAgreementHistModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal";
import SpfImportFromFileModal from '@/modules/budget/alteration/form/tabs/components/CreateForm/SpfImportFromFileModal.vue';
import {Ax} from "@/utils";

export default {
  name: "newAlterationForm",
  components: { DatePicker,
    Multiselect,
    CSpfTreeTable,
    'c-inc-add-modal': CIncomeAddItemModal,
    'c-spf-add-modal': CSpfAddItemModal,
    'c-inc-tree-table': CIncomeTreeTable,
    'loading': VueElementLoading,
    'c-budg-agr-modal': CBudgetAgreementModal,
    'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
    SpfImportFromFileModal
  },
  props: {
    currentAlteration: Object,
    currAbp: null,
    currGu: null,
    currRegion: null,
    guBase: null,
    listAlteration: null,
    accessLevel: Object,
    moduleCode: null,
    mode: null,
    newRequest: null,
    activeTab: String,
    tableType: Number
  },
  created() {
  },
  beforeUpdate() {
    if (this.formObligation !== null) {
      this.formObligation.forEach((row) => {
        if (row.hasOwnProperty('childVis')) {
          if (row.childVis) {
            row._rowVariant = 'info';
          }
        }
      });
    }

    if (this.formPayment !== null) {
      this.formPayment.forEach((row) => {
        if (row.hasOwnProperty('childVis')) {
          if (row.childVis) {
            row._rowVariant = 'info';
          }
        }
      });
    }
  },
  data() {
    return {
      tableFields: [
        {
          key: 'spf',
          label: 'Код специфики',
          class: 'toggle-show'
        },
        {
          key: 'project',
          label: 'Проект',
          class: 'toggle-show'
        },
        {
          key: 'name_ru',
          label: 'Наименование'
        },
        {
          key: 'sums',
          label: 'Сумма по заявке'
        },
        {
          key: 'january',
          label: 'Январь'
        },
        {
          key: 'february',
          label: 'Февраль'
        },
        {
          key: 'march',
          label: 'Март'
        },
        {
          key: 'april',
          label: 'Апрель'
        },
        {
          key: 'may',
          label: 'Май'
        },
        {
          key: 'june',
          label: 'Июнь'
        },
        {
          key: 'july',
          label: 'Июль'
        },
        {
          key: 'august',
          label: 'Август'
        },
        {
          key: 'september',
          label: 'Сентябрь'
        },
        {
          key: 'october',
          label: 'Октябрь'
        },
        {
          key: 'november',
          label: 'Ноябрь'
        },
        {
          key: 'december',
          label: 'Декабрь'
        }
      ],
      tableData: [],
      currDate: `${new Date()}`,
      currYear: `${new Date().getFullYear()}`,
      currMonth: `${new Date().getMonth() + 1}`,
      monthArr: [],
      currPrg: {},
      prgList: [],
      currPpr: [],
      pprList: [],
      currSpf: [],
      spfList: [],
      obligations: [],
      payments: [],
      obligationRes: [],
      paymentRes: [],
      spfText: '',
      formObligation: [],
      formPayment: [],
      formIncome: [],
      showObligation: true,
      showPayment: true,
      spfArray: [],
      loading: false,
      selectedAlteration: null,
      agrMap: new Map(),
      agrBtnLst: { back: [], forward: [] },
      operationCode: [],
      agrEnabled: false,
      showAddItemModal: false,
      showIncomAddItemModal: false,
      showImportFileModal: false,
      importTypeTable: 1,
      textErrFlk3: 'Годовая сумма по обязательствам должна быть равна годовой сумме по платежам',
      defMonth: 0,
      flc1: '',
      flc2: '',
      flc3: '',
      flc4: '',
      butBlock: 0
    };
  },
  async mounted() {
    this.loading = true;
    await this.getFlk();
    await this.loadingArrData();
    const date = new Date(this.currentAlteration.date);
    const month = this.padLeadingZeros(date.getMonth() + 1, 2);
    this.currYear = date.getFullYear();
    this.currDate = new Date(this.currentAlteration.date);
    this.currMonth = parseInt(month);
    for (const item of this.listAlteration) {
      if (item.code === this.currentAlteration.requestType) {
        this.selectedAlteration = item;
      }
    }
    if (((this.reqType && this.currentAlteration.level === 'gu' && [1, 3].includes(this.currentAlteration.status.status)) || (!this.reqType && this.currentAlteration.level === 'uf' && [1, 3].includes(this.currentAlteration.status.status))) && (this.accessLevel.editAccess)) {
      for (let i = this.currMonth - 1; i < 12; i++) {
        this.monthArr.push(i);
      }
    }
    if (this.monthArr.length > 0) {
      this.defMonth = this.monthArr[0];
    }
    if (this.formObligationFact !== undefined) {
      this.formObligationFact.forEach((item, index) => {
        this.combinationsArrays(item, 0, false);
      });
    }
    if (this.formPaymentFact !== undefined) {
      this.formPaymentFact.forEach((item, index) => {
        this.combinationsArrays(item, 1, false);
      });
    }
    if (this.formIncomeFact !== undefined) {
      this.formIncomeFact.forEach((item, index) => {
        this.combinationsArrays(item, -1, false);
      });
    }
    if (!(this.currentAlteration.level === 'gu')) {
      this.butBlock = 0;
    }
    if (this.currentAlteration.level === 'gu') {
      this.butBlock = 0;
      this.flk3Check(this.formObligation, this.formPayment);
      this.flk3Check(this.formPayment, this.formObligation);
      this.flk1Checks(this.formObligation, this.formPayment, 1);
      this.flk1Checks(this.formPayment, this.formObligation, 0);
    }
    await this.loadOperations();
    await this.agreementEvent(false);
    this.$watch('currentAlteration', () => {
      this.agreementEvent(false);
      this.currDate = this.currentAlteration.date;
    }, { deep: true });
    this.loading = false;
  },
  computed: {
    refSPF() {
      if (this.spfText.length > 0) { return this.spfText; }
      else { return '' }
    },
    refDate() {
      const date = new Date(this.currDate);
      return date.toLocaleDateString();
    },
    reqType() {
      return (!['budget_income_clarification', 'budget_income', 'budget_income_movement'].includes(this.currentAlteration.requestType));
    },
    uSumVisible() {
      if (this.currentAlteration.requestType === 'budget_adjustment') {
        return 1;
      }
      else if (this.currentAlteration.requestType === 'budget_clarification') {
        return 2;
      }
      else { return 0; }
    }
  },
  watch: {

  },
  methods: {
    openFilterByRef(refName) {
      const drop = this.$refs.drop;
      drop.show(true);
      this.$emit('openFilterByRef', refName);
      let refItem;
      switch (refName) {
        case 'dateRef':
          refItem = this.$refs.dateRef;
          break;
        case 'prgRef':
          refItem = this.$refs.prgRef;
          break;
        case 'pprRef':
          refItem = this.$refs.pprRef;
          break;
        case 'spfRef':
          refItem = this.$refs.spfRef;
          break;
        case 'refAlteration':
          refItem = this.$refs.refAlteration;
          break;
        case 'filterGu':
          refItem = this.$refs.filterGu;
          break;
        default: break;
      }
      setTimeout(() => refItem.$el.focus(), 100);
    },
    onBack() {
      this.$emit('onBack', false);
    },
    onSave() {
      this.$bvModal.msgBoxConfirm(
          'Вы действительно хотите сохранить данные?',
          {
            title: 'Подтверждение',
            size: 'lg',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'ДА',
            cancelTitle: 'Отмена',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              if (this.reqType) {
                this.loading = true;
                this.tableData = [];
                this.save(this.formObligation);
                this.save(this.formPayment);
                fetch("/api/budget-execution-alteration?requestId=" + this.currentAlteration.id, {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  method: "POST",
                  body: JSON.stringify(this.tableData)
                })
                    .then((res) => this.makeToast('success', 'Сообщение', 'Данные сохранены'),  this.loading = false)
                    .catch((err) => this.makeToast('danger', 'Ошибка сохранения', err.toString()),  this.loading = false);
              } else {
                this.loading = true;
                this.tableData = [];
                this.save(this.formIncome);
                fetch("/api/budget-execution-alteration/income?requestId=" + this.currentAlteration.id, {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  method: "POST",
                  body: JSON.stringify(this.tableData)
                })
                    .then((res) => this.makeToast('success', 'Сообщение', 'Данные сохранены'),  this.loading = false)
                    .catch((err) => this.makeToast('danger', 'Ошибка сохранения', err.toString()),  this.loading = false);
              }

            }
          })
          .catch(error => {
            this.makeToast('danger', 'Ошибка сохранения', error.toString());
          });
    },
    getSum(obj) {
      let sum = 0;
      for (let i=0; i < 12; i++) {
        if (obj[`month${i}`]) {
          sum = Math.round(((typeof obj[`month${i}`] == 'number' ? obj[`month${i}`] : parseFloat(obj[`month${i}`])) + sum)*10)/10;
        }
      }
      return sum;
    },
    getPlanVal(obj) {
      switch (obj) {
        case 0:
          return 'january';
          break;
        case 1:
          return 'february';
          break;
        case 2:
          return 'march';
          break;
        case 3:
          return 'april';
          break;
        case 4:
          return 'may';
          break;
        case 5:
          return 'june';
          break;
        case 6:
          return 'july';
          break;
        case 7:
          return 'august';
          break;
        case 8:
          return 'september';
          break;
        case 9:
          return 'october';
          break;
        case 10:
          return 'november';
          break;
        case 11:
          return 'december';
          break;
      }
    },
    save: function (dataTable) {
      if (this.reqType) {
        dataTable.forEach(item => {
          [...Array(12)].map((_, i) => {
            if (item.plan_type === undefined) {
            }
            let lm = i;
            if (item['month' + i] !== 0) {
              if (item.leftMonth >= i) {
                lm = item.leftMonth;
              } else {
                lm = i;
              }
              this.tableData.push({
                id: null,
                budgetExecutionAlterationRequestId: this.currentAlteration.id,
                region: item.region,
                year: item.year,
                gu: item.gu,
                gr: item.gr,
                abp: item.abp,
                prg: item.prg,
                ppr: item.ppr,
                spf: item.spf,
                planType: item.planType,
                bip_code: item.bip_code,
                month: i,
                value: item['month' + i] * 1,
                leftMonth: lm,
                left_amount: item.left_amount
              })
            }
          });
        });
      } else {
        dataTable.forEach(elKat => {
          elKat.child_list.forEach(
              elClss => {
                elClss.child_list.forEach(
                    elPcl => {
                      elPcl.child_list.forEach(
                          item => {
                            [...Array(12)].map((_, i) => {
                              if (item['month' + i] !== 0) {
                                this.tableData.push({
                                  id: null,
                                  budgetExecutionAlterationRequestId: this.currentAlteration.id,
                                  region: this.currRegion.code,
                                  year: this.currYear,
                                  kat: item.kat,
                                  cls: item.clss,
                                  pcl: item.pcl,
                                  spf: item.spf,
                                  month: i,
                                  value: item['month' + i] * 1
                                })
                              }
                            });
                          }
                      )
                    }
                )
              }
          )
        });
      }
    },
    chgDataObl(data) {
      try {
        this.formObligation = data.tableData;
        this.butBlock = 0;
        const row1 = this.formObligation[data.indx];
        const row1Old = JSON.parse(JSON.stringify(row1));
        //Остатки
        const curMonth = parseFloat(data.fieldName.toString().slice(5));
        if (!row1.flk) { row1.flk = {}; }
        if (!row1.flk[`month${this.defMonth}`]) { row1.flk[`month${this.defMonth}`] = []; }
        row1['leftMonth'] = this.defMonth;
        if (curMonth === this.defMonth) {
          row1.flk[`month${this.defMonth}`] = [];
          row1.flk[`sum`] = [];
          for (let i = this.defMonth - 1; i > -1; i--) {
            row1['month' + i] = 0;
          }
          row1.flk[`month${this.defMonth}`] = [];
          if (row1[data.fieldName] < 0) {
            if (this.planSum(row1, this.defMonth + 1) > Math.abs(row1[data.fieldName])) {
              let diff = row1[data.fieldName] + row1[this.getPlanVal(curMonth)];
              if (diff < 0) {
                row1['sum_val'] = row1[data.fieldName];
                row1['left_amount'] = row1[data.fieldName];
                row1[data.fieldName] = -1 * Math.abs(row1[this.getPlanVal(curMonth)]);
                for (let i = this.defMonth - 1; i > -1; i--) {
                  diff = row1[this.getPlanVal(i)] + diff;
                  if (diff < 0) {
                    row1['month' + i] = -1 * row1[this.getPlanVal(i)];
                  } else {
                    row1['month' + i] = -1 * (row1[this.getPlanVal(i)] - diff);
                    break;
                  }
                }
              }
            }

          }
        }
        const row2 = this.findRow(row1, this.formPayment);
        this.flk1Check(row1, row2, this.formPayment, 1);
        if (JSON.stringify(row1.flk) !== JSON.stringify(row1Old.flk)) {
          this.formObligation.splice(data.indx, 1, row1);
        }
        // FLK3
        this.flk3Check(this.formObligation, this.formPayment);
        this.flk3Check(this.formPayment, this.formObligation);
      } catch (error) {
        this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
      }
    }, // обновление параметров
    chgDataPay(data) {
      try {
        this.formPayment = data.tableData;
        this.butBlock = 0;
        const row1 = this.formPayment[data.indx];
        const row1Old = JSON.parse(JSON.stringify(row1));
        //Остатки
        const curMonth = parseFloat(data.fieldName.toString().slice(5));
        if (!row1.flk) { row1.flk = {}; }
        if (!row1.flk[`month${this.defMonth}`]) { row1.flk[`month${this.defMonth}`] = []; }
        row1['leftMonth'] = this.defMonth;
        if (curMonth === this.defMonth) {
          row1.flk[`month${this.defMonth}`] = [];
          row1.flk[`sum`] = [];
          for (let i = this.defMonth - 1; i > -1; i--) {
            row1['month' + i] = 0;
          }
          if (row1[data.fieldName] < 0) {
            if (this.planSum(row1, this.defMonth + 1) > Math.abs(row1[data.fieldName])) {
              let diff = row1[data.fieldName] + row1[this.getPlanVal(curMonth)];
              if (diff < 0) {
                row1['sum_val'] = row1[data.fieldName];
                row1['left_amount'] = row1[data.fieldName];
                row1[data.fieldName] = -1 * Math.abs(row1[this.getPlanVal(curMonth)]);
                for (let i = this.defMonth - 1; i > -1; i--) {
                  diff = row1[this.getPlanVal(i)] + diff;
                  if (diff < 0) {
                    row1['month' + i] = -1 * row1[this.getPlanVal(i)];
                  } else {
                    row1['month' + i] = -1 * (row1[this.getPlanVal(i)] - diff);
                    break;
                  }
                }
              }
            }
          }
        }

        const row2 = this.findRow(row1, this.formObligation);
        this.flk1Check(row1, row2, this.formObligation, 0);
        if (JSON.stringify(row1.flk) !== JSON.stringify(row1Old.flk)) {
          this.formPayment.splice(data.indx, 1, row1);
        }
        // FLK3
        this.flk3Check(this.formPayment, this.formObligation);
        this.flk3Check(this.formObligation, this.formPayment);
      } catch (error) {
        this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
      }
    }, // обновление параметров
    planSum(data, month) {
      let sum = 0;
      for (let i = month - 1; i > -1; i--) {
        sum = sum + data[this.getPlanVal(i)];
      }
      return sum;
    },
    monthSum(data, month) {
      let sum = 0;
      for (let i = month - 1; i > -1; i--) {
        sum = sum + data['month' + i];
      }
      return sum;
    },
    // FLK1
    flk1Check(row1, row2, data, type) {
      const row2Old = JSON.parse(JSON.stringify(row2));
      if (row2 !== null) {
        row2.flk[`month${this.defMonth}`] = [];
        switch (type) {
          case 0:
            if ((this.monthSum(row1, this.defMonth + 1) + this.planSum(row1, this.defMonth + 1)) > (this.monthSum(row2, this.defMonth + 1) + this.planSum(row2, this.defMonth + 1))) {
              if (!row1.flk) { row1.flk = {}; }
              if (!row1.flk[`month${this.defMonth}`]) {row1.flk[`month${this.defMonth}`] = [];}
              row1.flk[`month${this.defMonth}`].push({ typeErr: this.flc1, textErr: 'Внимание! Сумма по платежам превышает сумму по обязательствам' });
              row2.flk[`month${this.defMonth}`].push({ typeErr: this.flc1, textErr: 'Внимание! Сумма по платежам превышает сумму по обязательствам' });
              this.agrBtn(this.flc1.length > 0);
            }
          case 1:
            if ((this.monthSum(row1, this.defMonth + 1) + this.planSum(row1, this.defMonth + 1)) < (this.monthSum(row2, this.defMonth + 1) + this.planSum(row2, this.defMonth + 1))) {
              if (!row1.flk) {
                row1.flk = {};
              }
              if (!row1.flk[`month${this.defMonth}`]) {
                row1.flk[`month${this.defMonth}`] = [];
              }
              row1.flk[`month${this.defMonth}`].push({
                typeErr: this.flc1,
                textErr: 'Внимание! Сумма по платежам превышает сумму по обязательствам'
              });
              row2.flk[`month${this.defMonth}`].push({
                typeErr: this.flc1,
                textErr: 'Внимание! Сумма по платежам превышает сумму по обязательствам'
              });
              this.agrBtn(this.flc1.length > 0);
            }
        }
        if (JSON.stringify(row2.flk) !== JSON.stringify(row2Old.flk)) {
          data.splice(row2.index, 1, row2);
        }
      } else {
        if (!row1.flk) { row1.flk = {}; }
        if (!row1.flk[`month${this.defMonth}`]) { row1.flk[`month${this.defMonth}`] = []; }
        row1.flk[`month${this.defMonth}`].push({typeErr: this.flc1, textErr: 'Внимание! Сумма по платежам превышает сумму по обязательствам'});
        this.agrBtn(this.flc1.length > 0);
      }
    },
    flk1Checks(data1, data2, type) {
      if (data1.length !== 0) {
        for (let i = 0; i < data1.length; i++) {
          const row1 = data1[i];
          const row1Old = JSON.parse(JSON.stringify(row1));
          row1.flk[`month${this.defMonth}`] = [];
          const row2 = this.findRow(row1, data2);
          this.flk1Check(row1, row2, data2, type);
          if (JSON.stringify(row1.flk) !== JSON.stringify(row1Old.flk)) {
            data1.splice(i, 1, row1);
          }
        }
      }
    },
    findRow(row1, data2){
      let find = false;
      if (data2.length !== 0) {
        for (let j = 0; j < data2.length; j++) {
          const row2 = data2[j];
          if (row1.prg === row2.prg && row1.ppr === row2.ppr && row1.spf === row2.spf) {
            find = true;
            row2.index = j;
            return row2;
          }
        }
        if (!find) {
          return null;
        }
      }
      else { return null; }
    },
    // FLK3
    flk3Check(data1, data2) {
      // this.butBlock = false;
      if (data1.length !== 0) {
        for (let i = 0; i < data1.length; i++) {
          const row1 = data1[i];
          const row1Old = JSON.parse(JSON.stringify(row1));
          row1.flk.sum = [];
          if (data2.length === 0) {
            row1.flk.sum[row1.flk.sum.length] = { typeErr: this.flc3, textErr: this.textErrFlk3 };
            this.agrBtn(this.flc3.length > 0);
          } else {
            // let flcTrue = false;
            for (let j = 0; j < data2.length; j++) {
              const row2 = data2[j];
              const row2Old = JSON.parse(JSON.stringify(row2));
              if (row1.prg === row2.prg && row1.ppr === row2.ppr && row1.spf === row2.spf) {
                row2.flk.sum = [];
                if (this.getSum(row1) !== this.getSum(row2)) {
                  row1.flk.sum[row1.flk.sum.length] = { typeErr: this.flc3, textErr: this.textErrFlk3 };
                  row2.flk.sum[row2.flk.sum.length] = { typeErr: this.flc3, textErr: this.textErrFlk3 };
                  this.agrBtn(this.flc3.length > 0);
                }
                // flcTrue = true;
              }
              if (JSON.stringify(row2.flk.sum) !== JSON.stringify(row2Old.flk.sum)) {
                data2.splice(j, 1, row2);
              }
            }
            if (JSON.stringify(row1.flk.sum) !== JSON.stringify(row1Old.flk.sum)) {
              data1.splice(i, 1, row1);
            }
          }
        }
      }
    },
    agrBtn(val) {
      if (val) {
        this.butBlock = this.butBlock + 1;
      }
    },
    chgDataIncome(data) {
      try {
        this.formIncome = data;
      } catch (error) {
        this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
      }
    }, // обновление параметров
    toggleShowObligation() {
      this.showObligation = !this.showObligation;
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async loadingArrData () {
      const el = Object.assign({}, this.currentAlteration);
      el.year = this.currYear;
      let params = JSON.stringify(el);
      let abp = null;
      if (this.reqType) {
        if (this.currAbp !== null) {
            abp = this.currAbp.abp;
        }
        try {
          let resT = '';
          params = `{"year":${this.currYear},"abp":${abp},"gu":${this.currentAlteration.gu},"region":"${this.currRegion.code}","type":"obligation","requestId":"${this.currentAlteration.id}","requestType":"${this.currentAlteration.requestType}","budgetVersion":"${this.currentAlteration.budgetVersion}","level":"${this.currentAlteration.level}","date":"${this.formatDate(new Date(this.currentAlteration.date))}"}`;
          resT = await fetch(
              `/api-py/get-alteration-new-data/` + params);
          const arrDate =  await resT.json();
          this.formObligationPlan = [];
          this.formObligationPlan = arrDate.plan;
          this.formObligationFact = [];
          this.formObligationFact = arrDate.fact;
          this.oOstatki = [];
          this.oOstatki = arrDate.ost;

          this.formObligationPlan.forEach((item, index) => {
            this.$set(item, 'sum', item.month0 + item.month1 + item.month2 + item.month3 + item.month4 +
                item.month5 + item.month6 + item.month7 + item.month8 + item.month9 + item.month10 + item.month11);
          });
        }  catch (error) {
          this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
          this.loading = false;
        }
        try {
          let resP = '';
          params = `{"year":${this.currYear},"abp":${abp},"gu":${this.currentAlteration.gu},"region":"${this.currRegion.code}","type":"payment","requestId":"${this.currentAlteration.id}","requestType":"${this.currentAlteration.requestType}","budgetVersion":"${this.currentAlteration.budgetVersion}","level":"${this.currentAlteration.level}","date":"${this.formatDate(new Date(this.currentAlteration.date))}"}`;
          resP = await fetch(
              `/api-py/get-alteration-new-data/` + params);
          const arrDate =  await resP.json();
          this.formPaymentPlan = [];
          this.formPaymentPlan = arrDate.plan;
          this.formPaymentFact = [];
          this.formPaymentFact = arrDate.fact;
          this.pOstatki = [];
          this.pOstatki = arrDate.ost;

          this.formPaymentPlan.forEach((item, index) => {
            this.$set(item, 'sum', item.month0 + item.month1 + item.month2 + item.month3 + item.month4 +
                item.month5 + item.month6 + item.month7 + item.month8 + item.month9 + item.month10 + item.month11);
          });
        }  catch (error) {
          this.makeToast('danger', 'Предупреждение', 'Ошибка обновления параметров ' + error.toString());
          this.loading = false;
        }
      } else {
        this.formIncome = [];
        try {
          const resI = await fetch(
              `/api-py/get-income-data/{"region":"${this.currRegion.code}","year":${this.currYear},"spf":"${this.spfArr}","requestId":"${this.currentAlteration.id}"}`
          );
          const result = await resI.json();
          this.formIncomePlan = [];
          this.formIncomePlan = result.plan;
          this.formIncomeFact = [];
          this.formIncomeFact = result.fact;
          this.formIncomePlan.forEach((item, index) => {
            this.$set(item, 'sum', item.month0 + item.month1 + item.month2 + item.month3 + item.month4 +
                item.month5 + item.month6 + item.month7 + item.month8 + item.month9 + item.month10 + item.month11);
          });
        } catch (error) {
          this.makeToast('danger', 'Ошибка', error.toString());
          this.loading = false;
        }
      }
    },
    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    handleSelect(event) {
      if (event.length > 0) {
        event.sort((a, b) => (a.spf > b.spf) ? 1 : -1);
        if (parseInt(event[0].spf) === 0) {
          this.currSpf.splice(0);
          for (const item of this.spfList) {
            if (parseInt(item.spf) > 0) {
              this.currSpf.push(item);
              this.$emit('handle-select', item.value);
            }
          }
        } else {
          this.$emit('handle-select', event.value);
        }
      }
      this.textSPF();
    },
    textSPF() {
      this.spfText = ''
      if (this.currSpf !== undefined) {
        this.currSpf.sort((a, b) => (a.spf > b.spf) ? 1 : -1);
        for (const c of this.currSpf) {
          if (c.spf > 0) { this.spfText = this.spfText + c.spf + '; ' }
          else { this.spfText = 'Все'}
        }
      }
    },
      duplicatedData(tabIndex) {
          switch (tabIndex) {
              case 0:
                  this.formPayment = []
                  this.formPaymentFact = []
                  for (let j = 0; j < this.formObligation.length; j++) {
                      const newObj = Object.assign({}, this.formObligation[j])
                      for (let i = 0; i < 12; i++) {
                          delete newObj[this.getPlanVal(i)]
                      }
                      newObj.plan_type = 2;
                      newObj.planType = 2;
                      this.formPaymentFact.push(newObj)
                  }
                  for (const el of this.formPaymentFact) {
                      const objPayment = Object.assign({}, el);
                      delete objPayment.left_amount
                      delete objPayment.sum_val
                      let diffSum = 0;
                      for (let i = objPayment.leftMonth; i > -1; i--) {
                          diffSum += objPayment[`month${i}`]
                          objPayment[`month${i}`] = 0
                      }
                      objPayment[`month${objPayment.leftMonth}`] = diffSum
                      this.combinationsArrays(objPayment, 1, false);
                      this.chgDataPay({ tableData: this.formPayment, indx: this.formPayment.length - 1, chldIndx: undefined, fieldName: `month${objPayment.leftMonth}` })
                  }
                  this.flk3Check(this.formObligation, this.formPayment, 1);
                  this.flk1Checks(this.formPayment, this.formObligation, 0);
                  break;
              case 1:
                  this.formObligation = []
                  this.formObligationFact = []
                  for (let j = 0; j < this.formPayment.length; j++) {
                      const newObj = Object.assign({}, this.formPayment[j])
                      for (let i = 0; i < 12; i++) {
                          delete newObj[this.getPlanVal(i)]
                      }
                      newObj.plan_type = 1;
                      newObj.planType = 1;
                      this.formObligationFact.push(newObj)
                  }
                  for (const el of this.formObligationFact) {
                      for (let i=0; i < 12; i++) {
                          el.flk[`month${i}`] = [];
                      }
                      const objObligation = Object.assign({}, el);
                      delete objObligation.left_amount
                      delete objObligation.sum_val
                      let diffSum = 0;
                      for (let i = objObligation.leftMonth; i > -1; i--) {
                          diffSum += objObligation[`month${i}`]
                          objObligation[`month${i}`] = 0
                      }
                      objObligation[`month${objObligation.leftMonth}`] = diffSum
                      this.combinationsArrays(objObligation, 0, false);
                      this.chgDataObl({ tableData: this.formObligation, indx: this.formObligation.length - 1, chldIndx: undefined, fieldName: `month${objObligation.leftMonth}` })

                  }
                  this.flk3Check(this.formPayment, this.formObligation);
                  this.flk1Checks(this.formObligation, this.formPayment);
                  break;
          }
      },
      duplicatedData1(tabIndex) {
      this.butBlock = 0;
      switch (tabIndex) {
        case 0:
          this.formObligation.forEach((item1, index) => {
            let duplicate = false;
            this.formPayment.forEach((item2, index) => {
              if ((item1.prg === item2.prg) && (item1.ppr === item2.ppr) && (item1.spf === item2.spf) && item1.bip_code === item2.bip_code) {
                for (let i=0; i < 12; i++) {
                    item2[`month${i}`] = item1[`month${i}`];
                }
                item2.leftMonth = item1.leftMonth;
                  // let diffSum = 0;
                  // for (let i = item2.leftMonth; i > -1; i--) {
                  //     diffSum += item2[`month${i}`]
                  //     item2[`month${i}`] = 0
                  // }
                  // item2[`month${item2.leftMonth}`] = diffSum
                duplicate = true;
              }
            });
            if (!duplicate) {
              const item = Object.assign({}, item1);
              item.plan_type = 2;
              item.planType = 2;
              this.formPayment.push(item);
              this.formPayment.forEach((item2, index) => {
                if ((item.prg === item2.prg) && (item.ppr === item2.ppr) && (item.spf === item2.spf) && item.bip_code === item2.bip_code) {
                  for (let i=0; i < 12; i++) {
                      for (let i=0; i < 12; i++) {
                          item2[`month${i}`] = item[`month${i}`];
                      }
                  }
                  item2.leftMonth = item.leftMonth;
                  item2.flk = item.flk;
                    // let diffSum = 0;
                    // for (let i = item2.leftMonth; i > -1; i--) {
                    //     diffSum += item2[`month${i}`]
                    //     item2[`month${i}`] = 0
                    // }
                    // item2[`month${item2.leftMonth}`] = diffSum
                }
              });
            }
          });
          this.flk3Check(this.formObligation, this.formPayment, 1);
          this.flk1Checks(this.formPayment, this.formObligation, 0);
          break;
        case 1:
          this.formPayment.forEach((item1, index) => {
            let duplicate = false;
            this.formObligation.forEach((item2, index) => {
              if ((item1.prg === item2.prg) && (item1.ppr === item2.ppr) && (item1.spf === item2.spf) && item1.bip_code === item2.bip_code) {
                for (let i=0; i < 12; i++) {
                  switch (i) {
                    case 0:
                      item2.month0 = item1.month0;
                      break;
                    case 1:
                      item2.month1 = item1.month1;
                      break;
                    case 2:
                      item2.month2 = item1.month2;
                      break;
                    case 3:
                      item2.month3 = item1.month3;
                      break;
                    case 4:
                      item2.month4 = item1.month4;
                      break;
                    case 5:
                      item2.month5 = item1.month5;
                      break;
                    case 6:
                      item2.month6 = item1.month6;
                      break;
                    case 7:
                      item2.month7 = item1.month7;
                      break;
                    case 8:
                      item2.month8 = item1.month8;
                      break;
                    case 9:
                      item2.month9 = item1.month9;
                      break;
                    case 10:
                      item2.month10 = item1.month10;
                      break;
                    case 11:
                      item2.month11 = item1.month11;
                      break;
                  }
                }
                item2.leftMonth = item1.leftMonth;
                duplicate = true;
              }
            });
            if (!duplicate) {
              const item = Object.assign({}, item1);
              item.plan_type = 1;
              item.planType = 1;
              this.formObligation.push(item);
              this.formObligation.forEach((item2, index) => {
                if ((item.prg === item2.prg) && (item.ppr === item2.ppr) && (item.spf === item2.spf) && item.bip_code === item2.bip_code) {
                  for (let i=0; i < 12; i++) {
                    switch (i) {
                      case 0:
                        item2.month0 = item.month0;
                        break;
                      case 1:
                        item2.month1 = item.month1;
                        break;
                      case 2:
                        item2.month2 = item.month2;
                        break;
                      case 3:
                        item2.month3 = item.month3;
                        break;
                      case 4:
                        item2.month4 = item.month4;
                        break;
                      case 5:
                        item2.month5 = item.month5;
                        break;
                      case 6:
                        item2.month6 = item.month6;
                        break;
                      case 7:
                        item2.month7 = item.month7;
                        break;
                      case 8:
                        item2.month8 = item.month8;
                        break;
                      case 9:
                        item2.month9 = item.month9;
                        break;
                      case 10:
                        item2.month10 = item.month10;
                        break;
                      case 11:
                        item2.month11 = item.month11;
                        break;
                    }
                  }
                  item2.leftMonth = item.leftMonth;
                  item2.flk = item.flk;
                }
              });
            }
          });
          this.flk3Check(this.formPayment, this.formObligation);
          this.flk1Checks(this.formObligation, this.formPayment);
          break;
      }
    },
    padLeadingZeros(num, size) {
      let s = String(num);
      while (s.length < size) {
        s = '0' + s;
      }
      return s;
    },
    makeToast(variant, title, tostbody) {
      this.$bvToast.toast(tostbody, {
        title: title,
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    async loadOperations() {
      this.operationCode = [];
      if (store.state.user.sub === null) { return; }
      let result = null;
      try {
        result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=${this.moduleCode}`))
            .then(response => response.json());
      } catch (error) {
        makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', error.toString());
        return;
      }
      if (result.operations) { this.operationCode = result.operations; }
    },
    // кнопка действия
    async agreementEvent(show = true) {
      this.selectArr = [];
      this.agrBtnLst = { back: [], forward: [] };
      const params = { modeCode: this.mode, operationCode: this.operationCode, agrCode: this.currentAlteration.status.status };
      if (!show) { this.agrEnabled = false; }
      let result = null;
      try {
        const response = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
        result = await response.json();
      } catch (error) {
        makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', error.toString());
        return;
      }
      if (result.length === 0) {
        makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
        return;
      }
      for (const el of result) {
        if (el.stepType === 1) {
          this.agrBtnLst.back.push(el);
        } else {
          this.agrBtnLst.forward.push(el);
        }
      }
      if (this.agrBtnLst.back.length || this.agrBtnLst.forward.length) {
        this.agrEnabled = true;
      }
      if (show) { this.$refs.refAgrModal.showEvent(); }
    },
    async agrClick(data) {
      data.userId = store.state.user.sub;
      const saveObj = Object.assign({ bearId: this.currentAlteration.id }, data);

      let result = await fetch('/api-py/set_bear_agreement_step',
          {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(saveObj)
          });
      if (result.status === 200) {
        result = await result.json();
        if (result.result === 'success') {
          this.currentAlteration.status.status = result.data.__data__.status;
          this.$watch('currentAlteration', () => {
            this.agreementEvent(false);
          }, { deep: true });
          makeToast(this, 'success', 'Сохранение статуса заявки', 'Успешно');
        } else {
          makeToast(this, 'danger', 'Сохранение статуса заявки', `Ошибка ${result.data}`);
        }
        // this.updateDatas();

                this.$emit('onBack', false);
            } else {
                makeToast(this, 'danger', 'Сохранение статуса заявки', `Ошибка ${result.status} ${result.statusText}`);
                return;
            }
        },
        importSpf(type=1) {
            this.showImportFileModal = true
            this.importTypeTable = type
        },
        addItem() {
            this.showAddItemModal = true;
        },
        addItemIncome() {
            this.showIncomAddItemModal = true;
        },
        addedItems (items) {
            for (const el of items) {
                el.year = this.currYear;
                el.region = this.currRegion.code;
                el.gu = this.currentAlteration.gu;
                el.utochSum = 0;
                el.flk = {}
                el.flk.sum = [];
                for (let i=0; i < 12; i++) {
                    el.flk[`month${i}`] = [];
                }
                const objObligation = Object.assign({plan_type: 1, planType: 1}, el);
                const objPayment = Object.assign({plan_type: 2, planType: 2}, el);
                this.combinationsArrays(objObligation, 0, true);
                this.combinationsArrays(objPayment, 1, true);
            }
        },
        addedIncomeIntems (items) {
            for (const el of items) {
                // el.year = this.currYear;
                el.region = this.currRegion.code;
                const objIncome = Object.assign({ year: this.currYear }, el);
                //-----тут поля отдельные для payment и Obligation ---
                this.combinationsArrays(objIncome, -1, true);
            }
        },
        combinationsArrays(item, type, newEl) {
            const zeroItem = {
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                utochSum: 0
            }
            let objObligation = [];
            let objOPayment = [];
            let objIncome = []
            let findItem = false;
            let findElem = false;
            let findElemCls = false;
            let findElemPcl = false;
            let elPcl = [];
            let treeIncome = [];
            if (type === 0) {
                this.formObligationPlan.forEach((itemP, index) => {
                    if (item['gr'] === itemP['gr'] && item['abp'] === itemP['abp'] && item['prg'] === itemP['prg'] &&
                        item['ppr'] === itemP['ppr'] && item['spf'] === itemP['spf']) {
                        objObligation = Object.assign(itemP, item);
                        findItem = true;
                        this.oOstatki.forEach((itemO, index) => {
                            if (item['abp'] === itemO['abp'] && item['prg'] === itemO['prg'] &&
                                item['ppr'] === itemO['ppr'] && item['spf'] === itemO['spf']) {
                                objObligation.ost = itemO.ostO;
                            }
                        });
                    }
                });
                if (findItem) {
                    this.formObligation.push(objObligation);
                } else {
                    objObligation = Object.assign(zeroItem, item);
                    this.formObligation.push(objObligation);
                }
            } else if (type > 0) {
                this.formPaymentPlan.forEach((itemP, index) => {
                    if (item['gr'] === itemP['gr'] && item['abp'] === itemP['abp'] && item['prg'] === itemP['prg'] &&
                        item['ppr'] === itemP['ppr'] && item['spf'] === itemP['spf']) {
                        objOPayment = Object.assign(itemP, item);
                        findItem = true;
                        this.pOstatki.forEach((itemO, index) => {
                            if (item['abp'] === itemO['abp'] && item['prg'] === itemO['prg'] &&
                                item['ppr'] === itemO['ppr'] && item['spf'] === itemO['spf']) {
                                objOPayment.ost = itemO.ostP;
                            }
                        });
                    }
                });
                if (findItem) {
                    this.formPayment.push(objOPayment);
                } else {
                    objOPayment = Object.assign(zeroItem, item);
                    this.formPayment.push(objOPayment);
                }
            } else if (type < 0) {
                const items = item.child_list[0].child_list[0].child_list[0];
                this.formIncomePlan.forEach((itemP, index) => {
                    if (items['kat'] === itemP['kat'] && items['clss'] === itemP['cls'] && items['pcl'] === itemP['pcl'] &&
                        items['spf'] === itemP['spf']) {
                        items.january = itemP.january;
                        items.february = itemP.february;
                        items.march = itemP.march;
                        items.april = itemP.april;
                        items.may = itemP.may;
                        items.june = itemP.june;
                        items.july = itemP.july;
                        items.august = itemP.august;
                        items.september = itemP.september;
                        items.october = itemP.october;
                        items.november = itemP.november;
                        items.december = itemP.december;
                        objIncome = item;
                        findItem = true;
                    }
                });
                if (!findItem) {
                    objIncome = Object.assign(item, zeroItem);
                    const elemCls = objIncome['child_list']
                    for (let i = 0; i < elemCls.length; i++) {
                        Object.assign(elemCls[i], zeroItem);
                        const elemPcl = elemCls[i]['child_list']
                        for (let j = 0; j < elemPcl.length; j++) {
                            Object.assign(elemPcl[j], zeroItem);
                            const elemSpf = elemPcl[j]['child_list']
                            for (let l = 0; l < elemSpf.length; l++) {
                                Object.assign(elemSpf[l], zeroItem);
                            }
                        }
                    }
                }
                if (newEl) {
                    treeIncome =  this.formIncome.slice();
                    for (let i = 0; i < treeIncome.length; i++) {
                        const elKat = treeIncome[i];
                        if (elKat.kat === item.kat) {
                            findElem = true;
                            for (let i = 0; i < elKat['child_list'].length; i++) {
                                const elCls = elKat['child_list'][i];
                                if (elCls.kat === item.kat && elCls.clss === item.clss) {
                                    findElemCls = true;
                                    for (let i = 0; i < elCls['child_list'].length; i++) {
                                        elPcl = elCls['child_list'][i];
                                        if (elPcl.kat === item.kat && elPcl.clss === item.clss  && elPcl.pcl === item.pcl) {
                                            findElemPcl = true;
                                            elPcl['child_list'].push(objIncome['child_list'][0]['child_list'][0]['child_list'][0]);
                                            break;
                                        }
                                    }
                                    if (!findElemPcl) {
                                        elCls['child_list'].push(objIncome['child_list'][0]['child_list'][0]);
                                        break;
                                    }
                                }
                            }
                            if (!findElemCls) {
                                elKat['child_list'].push(objIncome['child_list'][0]);
                                break;
                            }
                        }
                    }
                    if (!findElem) {
                        treeIncome.push(objIncome);
                    }
                    this.formIncome = treeIncome;
                } else {
                    this.formIncome.push(objIncome);
                }
            }
        },
        async getFlk() {
            const params = { level: this.currentAlteration.level, requestType: this.currentAlteration.requestType };
            try {
                const response = await fetch(`/api-py/get-flk/${encodeURI(JSON.stringify(params))}`);
                const flcArr = await response.json();
                for (let i = 0; i < flcArr.length; i++) {
                    switch (flcArr[i].flk) {
                        case 1:
                            if (flcArr[i].class === 1)
                                this.flc1 = 'error';
                            break;
                        case 2:
                            if (flcArr[i].class === 1)
                                this.flc2 = 'error';
                            break;
                        case 3:
                            if (flcArr[i].class === 1)
                                this.flc3 = 'error';
                            break;
                        case 4:
                            if (flcArr[i].class === 1)
                                this.flc4 = 'error';
                            break;
                    }
                }
                // console.log('1', this.flc1, '2', this.flc2, '3', this.flc3, '4', this.flc4);
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка get-flk', error.toString());
            }
        },
        async downloadApplication(app) {
            const item = this.currentAlteration
            const year = new Date(item.date).getFullYear();
            const d = {
                'app': app,
                'id': item.id,
                'gu': item.gu,
                'year': year,
                'number': item.number,
                'request_type': item.requestType,
                'abp': item.abp,
                'treeData': []
            }
            const that = this;
            Ax(
                {
                    url: '/api-py/alteration_application/',
                    method: 'POST',
                    responseType: 'blob',
                    data: d
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    if (app === 1) {
                        link.setAttribute('download', 'application28.xlsx');// or any other extension
                    }
                    if (app === 2) {
                        link.setAttribute('download', 'application27.xlsx');// or any other extension
                    }
                    if (app == 3) {
                        link.setAttribute('download', 'application34.xlsx');// or any other extension
                    }
                    if (app == 4) {
                        link.setAttribute('download', 'application36.xlsx');// or any other extension
                    }
                    if (app == 5) {
                        link.setAttribute('download', 'application29.xlsx');// or any other extension
                    }
                    if (app == 6) {
                        link.setAttribute('download', 'application30.xlsx');// or any other extension
                    }
                    if (app == 7) {
                        link.setAttribute('download', 'application37.xlsx');// or any other extension
                    }
                    if (app == 8) {
                        link.setAttribute('download', 'application39.xlsx');// or any other extension
                    }
                    if (app == 9) {
                        link.setAttribute('download', 'application25.xlsx');// or any other extension
                    }
                    if (app == 10) {
                        link.setAttribute('download', 'application26.xlsx');// or any other extension
                    }
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            )
        },
        async imortObligations(arrData) {
            this.formObligation = []
            this.formObligationPlan = arrData.plan
            this.formObligationFact = arrData.fact
            for (const el of arrData.fact) {
                el.year = this.currYear;
                el.region = this.currRegion.code;
                el.utochSum = el.total;
                el.flk = {}
                el.flk.sum = [];
                for (let i=0; i < 12; i++) {
                    el.flk[`month${i}`] = [];
                }
                const objObligation = Object.assign({plan_type: 1, planType: 1}, el);
                this.combinationsArrays(objObligation, 0, true);
            }
            if (this.currentAlteration.level === 'gu') {
                this.flk3Check(this.formObligation, this.formPayment);
                this.flk3Check(this.formPayment, this.formObligation);
                this.flk1Checks(this.formObligation, this.formPayment, 0);
                this.flk1Checks(this.formPayment, this.formObligation, 1);
            }
        },
        async imortPayments(arrData) {
            this.formPayment = []
            this.formPaymentPlan = arrData.plan
            this.formPaymentFact = arrData.fact
            for (const el of arrData.fact) {
                el.year = this.currYear;
                el.region = this.currRegion.code;
                el.utochSum = el.total;
                el.flk = {}
                el.flk.sum = [];
                for (let i=0; i < 12; i++) {
                    el.flk[`month${i}`] = [];
                }
                const objPayment = Object.assign({plan_type: 2, planType: 2}, el);
                this.combinationsArrays(objPayment, 1, true);
            }
            if (this.currentAlteration.level === 'gu') {
                this.flk3Check(this.formObligation, this.formPayment);
                this.flk3Check(this.formPayment, this.formObligation);
                this.flk1Checks(this.formObligation, this.formPayment, 0);
                this.flk1Checks(this.formPayment, this.formObligation, 1);
            }
        }
    },
    filters: {
    }
}
</script>
<style lang="scss" scoped>
.icon-btn{
    width: 20px;
    height: 20px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid rgba(28, 42, 62, 1);
    border-radius: 50%;
    margin: 0;
}
.icon-btn i{
    font-size: 0.4444444444rem;
    color: rgba(28, 42, 62, 1);
}
.duplicate-button{
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 20px;
}
</style>