

































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'inc-tree-table'
})
export default class IncTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;

    private treeData: any[] = [];
    private monthLstEn: any[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    private monthLstRu: any[] = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];

    private allSum: any = { incval: 0, expval: 0, diffmonth1: 0, diffperiod1: 0, incscorr: 0, expscorr: 0, diffmonth2: 0, diffperiod2: 0 };

    private getTreeData() {
        if (!this.tableData) {
            this.treeData = [];
            return;
        }

        const treeData: any[]= [];
        const allSum: any = { incval: 0, expval: 0, diffmonth1: 0, diffperiod1: 0, incscorr: 0, expscorr: 0, diffmonth2: 0, diffperiod2: 0 };
        const tableData = JSON.parse(JSON.stringify(this.tableData.slice()));
        for (let i = 0; i < tableData.length; i++) {
            const el = tableData[i];
            const objEl = Object.assign({}, el);
            treeData.push(objEl);
            this.getSumTotal(allSum, objEl);
        }
        this.treeData = treeData;
        this.allSum = allSum;
    }

    private getSumTotal(total: any, obj: any) {
        if (obj.incval) { total.incval = Math.round(((typeof obj.incval == 'number' ? obj.incval : parseFloat(obj.incval)) + total.incval)*10)/10; }
        if (obj.expval) { total.expval = Math.round(((typeof obj.expval == 'number' ? obj.expval : parseFloat(obj.expval)) + total.expval)*10)/10; }
        if (obj.diffmonth1) { total.diffmonth1 = Math.round(((typeof obj.diffmonth1 == 'number' ? obj.diffmonth1 : parseFloat(obj.diffmonth1)) + total.diffmonth1)*10)/10; }
        if (obj.diffmonth1) { total.diffperiod1 = Math.round(((typeof obj.diffmonth1 == 'number' ? obj.diffmonth1 : parseFloat(obj.diffmonth1)) + total.diffperiod1)*10)/10; }
        if (obj.incscorr) { total.incscorr = Math.round(((typeof obj.incscorr == 'number' ? obj.incscorr : parseFloat(obj.incscorr)) + total.incscorr)*10)/10; }
        if (obj.expscorr) { total.expscorr = Math.round(((typeof obj.expscorr == 'number' ? obj.expscorr : parseFloat(obj.expscorr)) + total.expscorr)*10)/10; }
        if (obj.diffmonth2) { total.diffmonth2 = Math.round(((typeof obj.diffmonth2 == 'number' ? obj.diffmonth2 : parseFloat(obj.diffmonth2)) + total.diffmonth2)*10)/10; }
        if (obj.diffmonth2) { total.diffperiod2 = Math.round(((typeof obj.diffmonth2 == 'number' ? obj.diffmonth2 : parseFloat(obj.diffmonth2)) + total.diffperiod2)*10)/10; }
    }

    private mounted() {
        this.getTreeData();
        this.$watch('tableData', () => { this.getTreeData(); }, { deep: true });
    }

    private sumMonth(monthObj: any, fieldName: string) {
        if (!monthObj[fieldName]) { monthObj[fieldName] = 0; }
         return monthObj[fieldName];
    }
}
