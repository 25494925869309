
















import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    name: 'c-pop-up'
})
export default class CPopUp extends Vue {
    @Prop({
        required: false,
        default: null
    })
    private flk!: any;

    @Prop({
        required: false,
        default: ''
    })
    private fieldName!: string;

    private get errorLst() {
        if (this.flk && this.flk[this.fieldName]) {
            return this.flk[this.fieldName];
        }
        return [];
    }
}

