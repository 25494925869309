

































import { Vue, Component } from 'vue-property-decorator';
import AlterationReports from "@/modules/budget/alteration/form/tabs/AlterationReports.vue";
import RefForm from "@/modules/budget/alteration/RefForm.vue";
import ApplForm from "@/modules/budget/alteration/ApplForm.vue";

@Component({
    components: { RefForm, ApplForm, AlterationReports }
})
export default class AlterationForm extends Vue {
    private requestTypeOptions = [
        {
            code: "individual",
            localText: "Внутренняя",
            name_ru: "Внутренняя",
        },
        {
            code: "movement",
            localText: "Передвижка",
            name_ru: "Передвижка",
        },
        {
            code: "budget_adjustment",
            localText: "Корректировка",
            name_ru: "Корректировка",
        },
        {
            code: "budget_clarification",
            localText: "Уточнение",
            name_ru: "Уточнение",
        },
        {
            code: "budget_income",
            localText: "Поступления(скорректированные)",
            name_ru: "Поступления(скорректированные)",
        },
        {
            code: "budget_income_clarification",
            localText: "Поступления(уточненные)",
            name_ru: "Поступления(уточненные)",
        },
        {
            code: "budget_income_movement",
            localText: "Поступления(передвижка)",
            name_ru: "Поступления(передвижка)",
        }
    ];
    private regionBase = [];
    public yearList: any = [];
    public applicationsItem = {};
    public applicationsItems = {};
    public indexTab = 0;
    private reLoadChild = false;
    private get userId(): [] {
        return this.$store.getters.user_uuid;
    }
    public async loadRegions() {
        let listReg = null;
        try {
            const response = await fetch(
                `/api/budget-programs/budget_request_bp/budget_regions?user_uuid=${this.userId}`
            );
            listReg = await response.json();
            this.getRegions(listReg);
        } catch (error) {
            this.makeToast("danger", "Ошибка запроса регионов", error.toString());
        }
    }
    private applsABPActive(currentAlteration: {}) {
        this.applicationsItems = currentAlteration;
        this.indexTab = 2;
    }
    private reqABPActive(currentAlteration: {}) {
        this.applicationsItem = currentAlteration;
        this.indexTab = 1;
    }
    private refsABPActive(currentAlteration: {}) {
        this.applicationsItem = currentAlteration;
        this.indexTab = 1;
    }
    private onReLoadC(val: boolean) {
        this.reLoadChild = val;
    }
    private onUnReLoad(val: boolean) {
        this.reLoadChild = val;
    }
    private getRegions(listReg: any) {
        if (!listReg.length) return;
        this.regionBase = listReg
            .sort((a: any, b: any) => parseInt(a.code) - parseInt(b.code))
            .map((item: any) => {item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`;
                return item;
            });
        // this.currRegion = this.regionBase[0];
    }
    public async loadYears() {
        const yearNow = new Date().getFullYear();
        for (let i = yearNow; i >= yearNow - 10; i--) {
            if (i >= 2020) {
                this.yearList.push(i);
            }
        }
    }
    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    } // сообщение
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    public created() {}
    private async mounted()  {
        await this.loadRegions();
        await this.loadYears();
    }
}
