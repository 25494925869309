<template>
    <div>
        <b-tab :id="'alt-tab-5'" :active="scorplan" title="Скорректированный план" v-if="accScorr">
            <b-tabs class="tabs-in-tabs">
                <b-tab title="Расходы">
                    <expens-table :accessLevels="accessLevelsSP" :userId="userId" :regionBase="regionBase"></expens-table>
                </b-tab>
                <b-tab title="Доходы" v-if="['uf'].includes(accessLevelsSP.position)">
                    <income-table :accessLevels="accessLevelsSP" :userId="userId" :regionBase="regionBase"></income-table>
                </b-tab>
            </b-tabs>

        </b-tab>
        <b-tab :id="'alt-tab-6'" :active="utochplan" title="Уточненный план" v-if="accUtoch">
            <b-tabs class="tabs-in-tabs">
                <b-tab title="Расходы">
                    <alteration-utoch-plan-abp
                        :tab-type="1"
                        :region-base="regionBase"
                        :access-levels="accessLevelsUP"
                    ></alteration-utoch-plan-abp>
                </b-tab>
                <b-tab title="Доходы">
                    <alteration-utoch-plan-abp
                        :tab-type="2"
                        :region-base="regionBase"
                        :access-levels="accessLevelsUP"
                    ></alteration-utoch-plan-abp>
                </b-tab>
            </b-tabs>
        </b-tab>
        <b-tab :id="'alt-tab-7'" :active="balans" title="Баланс" v-if="accBalance">
            <balance-table :accessLevels="accessLevelsBal" :userId="userId" :regionBase="regionBase"></balance-table>
        </b-tab>
    </div>
</template>


<script>
import incomeTable from "@/modules/budget/alteration/form/tabs/components/reportsPlan/incomeTable";
import expensTable from "@/modules/budget/alteration/form/tabs/components/reportsPlan/expensTable";
import balanceTable from "@/modules/budget/alteration/form/tabs/components/reportsPlan/balanceTable";
import AlterationUtochPlanAbp from "@/modules/budget/alteration/form/tabs/components/utochPlan/AlterationUtochPlanAbp";
export default {
    name: "AlterationReports",
    components: { incomeTable, expensTable, balanceTable, AlterationUtochPlanAbp },
    props: ["regionBase"],
    computed: {
        userId() {
            return this.$store.getters.user_uuid;
        },
        accBalance() {
            return this.accessLevelsBal && this.accessLevelsBal.position !== undefined && ['uf'].includes(this.accessLevelsBal.position)
        },
        accScorr() {
            return this.accessLevelsSP && this.accessLevelsSP.position !== undefined
        },
        accUtoch() {
            return this.accessLevelsUP && this.accessLevelsUP.position !== undefined && ['ipf', 'abp', 'uf'].includes(this.accessLevelsUP.position)
        }
    },
    data(){
        return {
            reportTab: 0,
            scorplan: false,
            utochplan: false,
            balans: false,
            mCodeSP: '005.002.004',
            mCodeUP: '005.002.005',
            mCodeBal: '005.002.006',
            accessLevelsSP: {},
            accessLevelsUP: {},
            accessLevelsBal: {},
        }
    },
    async mounted() {
        this.accessLevelsSP = await this.getAccess(this.mCodeSP);
        this.accessLevelsUP = await this.getAccess(this.mCodeUP);
        this.accessLevelsBal = await this.getAccess(this.mCodeBal);
    },
    methods: {
        async getAccess(mCode) {
            try {
                const result = await fetch(`/api-py/get-access-info/${mCode}/${this.userId}`);
                if (result.status === 200) {
                    const res = await result.json();
                    if (res.id > 0) {
                        res.editAccess = res['access_level'] !== 1;
                        return res;
                    }
                } else {
                    this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
                    return null;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
                return null;
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
    }
}
</script>

<style scoped>

</style>