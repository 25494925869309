







import { Component, Vue, Prop } from 'vue-property-decorator';
import CPrgTree from '@/modules/budget/alteration/form/tabs/components/reportsPlan/tableDir/CPrgTree.vue';

@Component({
    name: 'prg-tree-table',
    components: {
        'c-prg-tree': CPrgTree
 }
})
export default class CPrgTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;
}

