<template>
    <div>
        <div v-if="!newForm">
            <div>
                <div class="filter-container">
                    <div class="left-content">
                        <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                            <template #button-content>
                                <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                                class="icon icon-keyboard"></i></span>
                            </template>
                            <div>
                                <div class="top-content">
                                    <span>Параметры фильтра</span>
                                    <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                                </div>
                                <!---------------------год------------------------->
                                <div class="filter-block">
                                    <b-form-group label="Год">
                                        <multiselect
                                            v-model="year"
                                            placeholder="Выберите год"
                                            :options="yearList"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="filterYear"
                                        >
                                        </multiselect>
                                    </b-form-group>
                                </div>
                                <!-------------------регион------------------------>
                                <div class="filter-block">
                                    <b-form-group label="Год">
                                        <multiselect
                                            v-model="curRegion"
                                            placeholder="Выберите регион"
                                            label="localText"
                                            :options="regionBase"
                                            :searchable="true"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            ref="filterRegion"
                                        >
                                        </multiselect>
                                    </b-form-group>
                                </div>

                                <div class="filter-block">
                                    <b-button variant="primary" @click="loadData(year, curRegion)">Применить</b-button>
                                </div>
                            </div>
                        </b-dropdown>
                    </div>
                    <div class="filter-actions">
                        <b-button v-if="hasCreateAccess()" variant="primary" @click="modalConsolOpen()">
                            <span>Создать</span>
                        </b-button>
                    </div>
                </div>
            </div>
            <!--  хлебные крошки   -->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" @click="openFilterByRef('filterYear')">{{ year }}</span>
                <span class="item-breadcrumb" @click="openFilterByRef('filterRegion')">{{ curRegion.localText }}</span>
            </div>
            <utoch-request-table
            :requests-base="requestsBase"
            :access-levels="accessLevels"
            :status-type="statusList"
            @onOpen="onOpen"
            @onChange="onChange"
            @onDelete="onDelete"
            ></utoch-request-table>
            <b-modal
                size="xl"
                id="modal-create"
                v-model="modalForm"
                centered
                hide-header
                hide-footer
            >
                <div class="add_modal">
                    <div class="add_modal__title">Создать</div>

                    <div class="add_modal__body">
                        <div class="add_modal__item">
                            <div class="add_modal__item__title">№</div>
                            <div class="date-picker-container">
                                <input
                                    type="number"
                                    class="add_modal__item__input"
                                    v-model="currentAlteration.number"
                                    disabled
                                />
                                Дата
                                <date-picker
                                    placeholder="Выберите дату"
                                    v-model="currentAlteration.date"
                                    format = "DD/MM/YYYY"
                                    type="date"
                                    style="max-width: 500px"
                                    :disabled="currAltStatusEdit"
                                />

                            </div>
                        </div>
                        <b-table
                            :items="list_of_available_references"
                            :fields="larTableFields"
                            :tbody-tr-class="rowClass"
                        >
                            <template #cell(requestType)="data">
                                {{ requestTypeOptions[data.value].localText }}
                            </template>
                            <template #cell(date)="data">
                                {{ getDateOfAgreement(data.item)|formatDate }}
                            </template>
                            <template #cell(check)="data">
                                <b-form-checkbox @change="onCheck(data.item)" v-model="data.item.check"></b-form-checkbox>
                            </template>
                        </b-table>
                    </div>
<!--                    <div v-if="saveTrue">-->
<!--                        <h6 style="color: red">Необходимо заполнить обязательные поля: "Вид заявки" и "Основание"</h6>-->
<!--                    </div>-->
                    <div class="add_modal__footer">
                        <b-button @click="saveRequest" variant="primary">Сохранить</b-button>
                        <b-button variant="secondary"  @click="modalForm = false">Отменить</b-button>
                    </div>
                </div>
            </b-modal>
        </div>
        <div v-if="newForm">
            <utoch-plan-form
                :current-utoch-plan="currentAlteration"
                :req-type="tabType"
                :region-base="regionBase"
                :cur-region="curRegion"
                :access-levels="accessLevels"
                @onBack="onBack"
            ></utoch-plan-form>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import UtochRequestTable from '@/modules/budget/alteration/form/tabs/components/utochPlan/UtochRequestTable'
import UtochPlanForm from '@/modules/budget/alteration/form/tabs/components/utochPlan/UtochPlanForm'
import axios from "axios"
import store from '@/services/store'

export default {
    name: "AlterationUtochPlanAbp",
    components: { DatePicker, 'loading': VueElementLoading, UtochRequestTable, UtochPlanForm },
    props: ['tabType', 'regionBase', 'accessLevels'],
    async created() {
        if (this.regionBase && this.regionBase.length > 0) this.curRegion = this.regionBase[0]
        if (this.curRegion) {
            // this.$watch('currRegion', this.loadData(this.year, this.currRegion));
            await this.loadData(this.year, this.curRegion);
        }
    },
    data() {
        return {
            userId: store.state.user.sub,
            // lar
            list_of_available_references: [],
            larTableFields: [
                {
                    key: 'check',
                    label: '',
                    thStyle: { width: "10%" },
                },
                {
                    key: 'number',
                    label: 'Номер справки',
                    thStyle: { width: "10%" },
                },
                {
                    key: 'date',
                    label: 'Дата согласования справки.',
                    thStyle: { width: "20%" },
                },
                {
                    key: 'description',
                    label: 'Основание',
                    thStyle: { width: "60%" },
                }
            ],
            requestReferenceLis: [],
            requestTypeOptions: {
                individual: {
                    code: "individual",
                    localText: "Внутренняя",
                    name_ru: "Внутренняя",
                },
                movement: {
                    code: "movement",
                    localText: "Передвижка",
                    name_ru: "Передвижка",
                },
                budget_adjustment: {
                    code: "budget_adjustment",
                    localText: "Корректировка",
                    name_ru: "Корректировка",
                },
                budget_clarification: {
                    code: "budget_clarification",
                    localText: "Уточнение",
                    name_ru: "Уточнение",
                },
                budget_income: {
                    code: "budget_income",
                    localText: "Поступления(скорректированные)",
                    name_ru: "Поступления(скорректированные)",
                },
                budget_income_clarification: {
                    code: "budget_income_clarification",
                    localText: "Поступления(уточненные)",
                    name_ru: "Поступления(уточненные)",
                },
                budget_income_movement: {
                    code: "budget_income_movement",
                    localText: "Поступления(передвижка)",
                    name_ru: "Поступления(передвижка)",
                }
            },
            loading: false,
            newForm: false,

            //filter
            curRegion: null,
            year: new Date().getFullYear(),
            // yearList: [],

            modalForm: false,
            currAltStatusEdit: false,
            currentAlteration: {
                id: null,
                number: null,
                gu: null,
                date: new Date(),
                requestType: null,
                budgetVersion: null,
                planType: null,
                requestLevel: null,
                user_id: null,
                create_date: null,
                update_date: null,
                delete_date: null,
                fileList: []
            },
            curentNumber: 0,

            requestsBase: [],
            requestsData: [],
            statusList: [],
            selectedStatus: null,
        }
    },
    computed: {
        mode() {
            return 'exu';
        },
        yearList() {
            const yearNow = new Date().getFullYear();
            const yearList = [];
            for (let i = yearNow; i >= yearNow - 10; i--) {
                if (i >= 2020) {
                    yearList.push(i);
                }
            }
            return yearList
        }
    },
    methods: {
        hasCreateAccess() {
            return this.accessLevels.position === 'uf'
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (item.selected) {
                return 'selected';
            }
        },

        getCurentAlteration() {

        },
        async getAvailableReferenceList() {
            await axios.get(`/api/budget-execution-alteration-uf-request/list?region=${this.curRegion.code}&year=${this.year}${this.tabType === 2? '&isIncome=true': ''}`)
            .then(response => {
                if (response.status === 200) {
                    const dataList = [];
                    let data  = response.data
                    data = data.filter((item) => {
                        if (!item.hasOwnProperty('status')) return false
                        return item.status.status === 39
                    })
                    for (const el of data) {
                        el.check = false
                        let exist = false;
                        for (const arl of this.requestReferenceLis) {
                            if (el.id === arl.budgetExecutionAlterationRequestId) {
                                el.check = true;
                                el.isDatabase = true;
                                exist = true;
                                break;
                            }
                        }

                        dataList.push(el)
                     }
                    this.list_of_available_references = dataList;
                }
            })
        },
        async getRequestReferenceList() {
            await axios.get(`/api/budget-execution-utoch/request?utochId=${this.currentAlteration.id}`).then(response => {
                if (response.status === 200) {
                    const reflist = [];
                    for (const el of response.data) {
                        el.check = true
                        reflist.push(el)
                    }
                    this.requestReferenceLis = reflist
                }
            }).catch(error => {

            })
        },
        getDateOfAgreement(row) {
            if (row.statusList && row.statusList.length) {
                let latestStatus26Data = null;
                let maxDate = -1;

                for (const item of row.statusList) {
                    if (item.status === 26 && item.create_date > maxDate) {
                        latestStatus26Data = item;
                        maxDate = item.create_date;
                    }
                }
                if (latestStatus26Data) return latestStatus26Data.create_date
            }
            if (row.status && row.status.hasOwnProperty('status')) return row.status.create_date
            return new Date().getMilliseconds()
        },
        async modalConsolOpen(item) {
            let requestTemplate = null
            if (!item) {
                const path = `/api/budget-execution-utoch/create-request-template?region=${this.curRegion.code}`
                await axios.get(path).then(response => {
                    if (response.status === 200) {
                        requestTemplate = response.data
                    }
                }).catch(error => {
                    console.log(error)
                    requestTemplate = null
                })
                if (requestTemplate) {
                    this.currentAlteration = requestTemplate
                }
                // this.currentAlteration.requestType = this.tabType === 1 ?'budget_utoch_expense': 'budget_utoch_income'
                this.currentAlteration.requestType = 'budget_utoch'
                this.currAltStatusEdit = false
                this.curentNumber = this.currentAlteration.number
                this.requestReferenceLis = []
            } else {
                this.currAltStatusEdit = true
                this.getRequestReferenceList()
            }
            if (!this.currentAlteration.user_id) {
                this.currentAlteration.user_id = this.userId;
            }
            this.currentAlteration.date = new Date(this.currentAlteration.date);
            this.getAvailableReferenceList()
            this.modalForm = true

        },
        async saveRequest() {
            this.saveTrue = false;
            this.cDis = true;

            this.modalForm = false;
            this.loading = true;
            await this.svRequest();
            this.loading = false;
        },
        async svRequest() {
            this.currentAlteration.date = this.currentAlteration.date.getTime();
            const res = await fetch("/api/budget-execution-utoch",
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: this.currAltStatusEdit? "PUT": "POST",
                    body: JSON.stringify(this.currentAlteration)
                })
                .then(async response => {
                    if (response.status === 200) {
                        await this.loadData(this.year, this.curRegion);
                        await this.saveReferences();
                        if (this.currAltStatusEdit) await this.deleteReferences();
                    }
                })
        },

        async saveReferences() {
            if (!this.currAltStatusEdit) {
                for (const el of this.requestsBase) {
                    if (el.number === this.curentNumber) {
                        this.currentAlteration = Object.assign({}, el);
                        break;
                    }
                }
            }
            for (const  el of this.list_of_available_references) {
                if (!el.isDatabase && el.check) {
                    await axios.post(`/api/budget-execution-utoch/request?utochId=${this.currentAlteration.id}&requestId=${el.id}`).then(resp => {
                        if (resp.status === 200) {}
                    }).catch(error => {})
                }
            }
        },
        async deleteReferences() {
            for (const  el of this.list_of_available_references) {
                if (el.isDatabase && !el.check) {
                    await axios.delete(`/api/budget-execution-utoch/request?utochId=${this.currentAlteration.id}&requestId=${el.id}`).then(resp => {
                        if (resp.status === 200) {}
                    }).catch(error => {})
                }
            }
        },

        async loadStatusList() {
            await axios.get('/api-py/get-status-list/' + this.mode).then(response => {
                if (response.status === 200) {
                    this.statusList = response.data
                }
                if (this.statusList.length) {
                    this.selectedStatus = this.listStatus.filter(stat => stat.code === 1)
                }
            })
        },

        async loadData(year, region) {
            let response = [];
            if ((year || region)) {
                try {
                    response = await fetch(
                        `/api/budget-execution-utoch?region=${region.code}&year=${year}`
                    );
                    response = await response.json();
                    this.getData(response);
                } catch (error) {
                    response = [];
                    this.makeToast(
                        "danger",
                        "Ошибка загрузки заключений",
                        error.toString()
                    );
                }
            }
        },
        getData(data) {
            this.loading = true;
            this.requestsData = data.sort((a, b) => b.create_date - a.create_date);
            // if (this.tabType === 1) this.requestsData = this.requestsData.filter(item => item.requestType === 'budget_utoch_expense')
            // else this.requestsData = this.requestsData.filter(item => item.requestType === 'budget_utoch_income')
            this.addUserName(this.requestsData);
            this.requestsBase = this.requestsData;
            // this.requestsBase.forEach((item, index) => {item.index = index})
            this.loading = false;
        },
        addUserName(data) {
            const userIdList = [];
            data.forEach((item) => {userIdList.push(item.user_id);});
            const unicUserIdList = [...new Set(userIdList)];
            this.loadUsers(unicUserIdList.join(","));
        },
        async loadUsers() {
            try {
                const response = await fetch('/api-py/get-realm-users/');
                this.userList = await response.json();
                this.getUsers(this.userList);
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки пользователей', error.toString());
            }
        },
        getUsers(data) {
            this.progress = 0;
            if (!this.requestsBase) return;
            this.requestsBase.forEach((item) => {
                const currUser = data.filter((user) => user.id === item.user_id)[0];
                if (currUser !== undefined){
                    const userName = `${currUser.firstName} ${currUser.lastName}`;
                    this.$set(item, "userName", userName);
                }
            });
        },


        onOpen(val) {
            this.currentAlteration = val;
            this.newForm = true;
        },
        onChange(val) {
            this.currentAlteration = val;
            this.modalConsolOpen(this.currentAlteration, true);
        },
        onDelete(val) {
            if (val) {
                this.deleteRequest(val);
            }
        },
        deleteRequest(item) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление данной записи...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        await axios.delete(`/api/budget-execution-utoch?utochId=${item.id}`)
                        .then(response => {
                           if (response.status === 200) {
                               // const ind1 = this.requestsData.indexOf(item);
                               // this.requestsData.splice(ind1, 1);
                               const ind2 = this.requestsBase.indexOf(item);
                               this.requestsBase.splice(ind2, 1);
                           }
                        })
                        .catch(error => {/* Handle error make Toast?*/});

                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },

        onBack(val) {
            this.newForm = val;
            this.loadData(this.year, this.curRegion);
        },






        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>

<style scoped lang="scss">
    .selected {
        background: gainsboro;
    }
    .date-picker-container {
        display: flex;
        gap: 79px;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #6087a0;
    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
    }
    .add_modal {
        width:100%;
        height:100%;
        &__body {
            background-color: #f7f9fc;
            border: 1px solid #d3dbe3;
            border-radius: 5px;
            margin-bottom: 23px;
        }

        &__title {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 26px;
        }

        &__item {
            display: flex;
            align-items: center;
            height: 50px;
            border: 1px solid #d3dbe3;
            justify-content: space-between;
            padding: 0px 10px 0px 16px;

            &__title {
                font-weight: 500;
                font-size: 14px;
                color: #6087a0;
            }
        }
    }
</style>
