<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------data------------------------->
                            <div class="filter-block">
                                <b-form-group label="Дата">
                                    <date-picker
                                        placeholder="Выберите дату"
                                        v-model="date"
                                        format = "YYYY-DD-MM"
                                        type="date"
                                        style="max-width: 1200px"
                                        ref="filterDate"
                                    />
                                </b-form-group>
                            </div>
                            <!---------------------АБП------------------------->
                            <div class="filter-block">
                                <b-form-group label="АБП">
                                    <multiselect
                                        v-model="currAbp"
                                        label="localText"
                                        placeholder="Выберите АБП"
                                        :options="abpBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="loadCompany"
                                        ref="filterAbp"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------ГУ-------------------------->
                            <div class="filter-block">
                                <b-form-group  label="Подведомственные ГУ" v-if="visible">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="checkedGU" @input="checkGU" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group label="ГУ" v-if="['ipf', 'uf'].includes(accessLevels.position) || (['abp'].includes(accessLevels.position) && checkedGU)">
                                    <multiselect
                                        :disabled="!guBase.length"
                                        v-model="currGu"
                                        label="localText"
                                        placeholder="Выберите ГУ"
                                        :options="guBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        ref="filterGu"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!---------------------Регион---------------------->
                            <div class="filter-block">
                                <b-form-group label="Область/район">
                                    <multiselect
                                        v-model="currRegion"
                                        label="localText"
                                        placeholder="Выберите регион"
                                        :options="regionBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        ref="filterReg"
                                    />
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group  label="Вид данных">
                                    <b-form-radio-group
                                        ref="bcVersion"
                                        id="radio-group-1"
                                        v-model="version"
                                        :options="versions"
                                        name="radio-options_1"
                                    >
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-form-group  label="Остатки">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="ost" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <div class="filter-block">
                                <b-button variant="primary" @click="loadData()">Применить</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <div class="actions-tab in-tab">
            <!---------------Форма для скачивания отчета--------------->
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="download">Скорректированный план(расходы)</b-dropdown-item>
            </b-dropdown>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="date" @click="openFilterByRef('filterDate')"> {{ date | formatDate }} </span>
            <span class="item-breadcrumb" v-if="currAbp" @click="openFilterByRef('filterAbp')"> {{currAbp.localText}}-АБП </span>
            <span class="item-breadcrumb" v-if="(currGu && ['ipf', 'uf'].includes(accessLevels.position))" @click="openFilterByRef('filterGu')"> {{currGu.localText}}-ГУ </span>
            <span class="item-breadcrumb" v-if="currRegion" @click="openFilterByRef('filterReg')"> {{ currRegion.localText}} </span>
            <span class="item-breadcrumb" @click="openFilterByRef('bcVersion')"> {{ versions[version].text }} </span>
        </div>
        <div class="top-table-line"></div>
        <prg-tree-table :table-data="budgetForm" :accessLevel="accessLevels"/>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VueElementLoading from "vue-element-loading";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Ax} from "@/utils"
import moment from 'moment';
import PrgTreeTable from "./PrgTreeTable";
// расходы
export default {
    name: "expensTable",
    components: { DatePicker, 'loading': VueElementLoading, PrgTreeTable },
    props: [ "accessLevels", "userId", "regionBase" ],
    data() {
        return {
            date: new Date(),
            loading: false,
            budgetForm: [],
            versions: [
                { value: 0, text: 'Обязательства' },
                { value: 1, text: 'Платежи' }
            ],
            version: 0,
            currAbp: null,
            abpBase: [],
            currGu: null,
            guBase: [],
            currRegion: null,
            ost: false,
            checkedGU: false,
            visible: false
        }
    },
    mounted() {
        this.version = 0;
        this.visible = ['abp'].includes(this.accessLevels.position);
        this.checkedGU = ['ipf', 'uf'].includes(this.accessLevels.position);
    },
    created() {
        this.loadAbpList();
        // const that = this;
        this.$watch('accessLevels', function () {
            this.visible = ['abp'].includes(this.accessLevels.position);
            this.checkedGU = ['ipf', 'uf'].includes(this.accessLevels.position);
        });
        this.$watch('regionBase', function () {
            this.currRegion = this.regionBase[0];
            this.chgParams();
        });
        // this.$watch('currRegion', that.chgParams);
        // this.$watch('currAbp', that.chgParams);
        // this.$watch('currGu', that.chgParams);

    },
    methods: {
        chgParams() {
            switch (this.accessLevels.position) {
                case 'ipf':
                    if (!this.currRegion || !this.currAbp || !this.currGu) { return; }
                    break;
                case 'abp':
                    if (!this.currRegion || !this.currAbp) { return; }
                    break;
                case 'uf':
                    if (!this.currRegion) { return; }
                    break;
                default: break;
            }
            this.loadData();
        },
        checkGU() {
            if (this.checkedGU) {
                this.currGu = this.guBase[0];
            } else {
                this.currGu = null;
            }
        },
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            this.$emit('openFilterByRef', refName);
            let refItem;
            switch (refName) {
                case 'bcVersion':
                    refItem = this.$refs.bcVersion;
                    break;
                case 'filterDate':
                    refItem = this.$refs.filterDate;
                    break;
                case 'filterAbp':
                    refItem = this.$refs.filterAbp;
                    break;
                case 'filterGu':
                    refItem = this.$refs.filterGu;
                    break;
                case 'filterReg':
                    refItem = this.$refs.filterReg;
                    break;
                default: break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        download() {
            const that = this;
            let params = {};
            if (['ipf', 'abp'].includes(that.accessLevels.position)) {
                if (this.currGu === null) {
                    params = `{"abp":${that.currAbp.abp},"gu":${null},"type":${that.version},"level":"${that.accessLevels.position}", "region":"${this.currRegion.code}", "date":"${moment(that.date).format('YYYY-MM-DD')}","ost":"${that.ost}"}`;
                }
                else {
                    params = `{"abp":${that.currAbp.abp},"gu":${that.currGu.code},"type":${that.version},"level":"${that.accessLevels.position}", "region":"${this.currRegion.code}", "date":"${moment(that.date).format('YYYY-MM-DD')}","ost":"${that.ost}"}`;
                }
            } else {
                let cAbp = null;
                let cGu = null;
                if ((this.currGu === null) || (this.currGu === undefined)) {
                    cGu = null;
                } else {
                    if (this.currGu.code === 0) {
                        cGu = null;
                    } else {
                        cGu = this.currGu.code;
                    }
                }
                if ((this.currAbp === null)  || (this.currAbp === undefined)) {
                    cAbp = null;
                } else {
                    if (this.currAbp.abp === 0) {
                        cAbp = null;
                    } else {
                        cAbp = this.currAbp.abp;
                    }
                }
                params = `{"abp":${cAbp},"gu":${cGu},"type":${this.version},"level":"${this.accessLevels.position}", "region":"${this.currRegion.code}", "date":"${moment(this.date).format('YYYY-MM-DD')}","ost":"${this.ost}"}`;
            }
            Ax(
                {
                    url: '/api-py/scorr-plan-expens-download/' + params, //  Тело файла
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Скорректированный план(Расходы).xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                    this.progress = 0;
                }
            );

        },
        async loadAbpList() {
            let response = [];
            response = await fetch('/api-py/user-abp-list/' + this.userId);
            const newBase = await response.json();
            this.abpBase = this.setIdArr(newBase, "abp");
            if (['uf'].includes(this.accessLevels.position)) {
                this.abpBase.push({
                    "name_ru": "Все",
                    "name_kk": "Барлығы",
                    "abp": 0
                });
            }
            if (this.abpBase.length > 0) {
                this.abpBase
                    .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1))
                    .map((item) => {
                        if (item.abp == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                        else
                            {item.localText = `${item.abp} - ${item["name_" + this.$i18n.locale]}`}
                        return item;
                    });
                this.currAbp = this.abpBase[0];
            }
            await this.loadCompany();
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (
                    result.length > 0 &&
                    el[codeName] === result[result.length - 1][codeName]
                ) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        async loadCompany() {
            let result = [];
            this.curComp = null;
            if (this.currAbp !== null) {
                try {
                    result = await fetch(`/api/budget-execution-base/user/gu?abp=${this.currAbp.abp}`)
                    // result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.currAbp.abp}/${this.userId}`)
                        .then(response => response.json());
                } catch (error) {
                    result = [];
                    this.makeToast('danger', 'Ошибка загрузки гос. учреждений666', error.toString());
                }
                if (result.length > 0) {
                    this.curComp = this.setNameLang(result[0], 'code');
                }
                this.guBase = result;
                if (['uf'].includes(this.accessLevels.position)) {
                    this.guBase.push({
                        "name_ru": "Все",
                        "name_kk": "Барлығы",
                        "code": 0
                    });
                }
                this.guBase.sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                    .map((item) => {
                        if (item.code == 0) {item.localText = `${item["name_" + this.$i18n.locale]}`}
                        else
                        {item.localText = `${item.code} - ${item["name_" + this.$i18n.locale]}`}
                        return item;
                    });
                if (this.checkedGU) {
                    this.currGu = this.guBase[0];
                }
            }
        },
        setNameLang(obj, codeName) {
            if (obj === null) { return null; }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        async loadData() {
            this.loading = true;
            let response = [];
            let params = {};
            try {
                this.budgetForm = [];
                if (['ipf', 'abp'].includes(this.accessLevels.position)) {
                    if (this.currGu === null) {
                        params = `{"abp":${this.currAbp.abp},"gu":${null},"type":${this.version},"level":"${this.accessLevels.position}", "region":"${this.currRegion.code}", "date":"${moment(this.date).format('YYYY-MM-DD')}","ost":"${this.ost}"}`;
                    }
                    else {
                        params = `{"abp":${this.currAbp.abp},"gu":${this.currGu.code},"type":${this.version},"level":"${this.accessLevels.position}", "region":"${this.currRegion.code}", "date":"${moment(this.date).format('YYYY-MM-DD')}","ost":"${this.ost}"}`;
                    }
                } else {
                    let cAbp = null;
                    let cGu = null;
                    if ((this.currGu === null) || (this.currGu === undefined)) {
                        cGu = null;
                    } else {
                        if (this.currGu.code === 0) {
                            cGu = null;
                        } else {
                            cGu = this.currGu.code;
                        }
                    }
                    if ((this.currAbp === null)  || (this.currAbp === undefined)) {
                        cAbp = null;
                    } else {
                        if (this.currAbp.abp === 0) {
                            cAbp = null;
                        } else {
                            cAbp = this.currAbp.abp;
                        }
                    }
                    params = `{"abp":${cAbp},"gu":${cGu},"type":${this.version},"level":"${this.accessLevels.position}", "region":"${this.currRegion.code}", "date":"${moment(this.date).format('YYYY-MM-DD')}","ost":"${this.ost}"}`;
                }
                console.log(params);
                if (this.currRegion !== undefined) {
                    response = await fetch(`/api-py/scorr-plan-expens/` + params);
                    this.budgetForm = await response.json();
                }
                this.loading = false;
            } catch (error) {
                response = [];
                this.loading = false;
                this.makeToast(
                    "danger",
                    "Ошибка загрузки  СкоррПлан(Расходы)",
                    'Не все параметры заданы'
                );
            }
            this.loading = false;
        },
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>


<style lang="scss" scoped>
.content {
    display: flex;
    gap: 20px;
    background-color: #eef1f8;
    justify-content: space-between;
}

.content {
    height: 100%;
    .title {
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        padding-left: 20px;
        margin-bottom: 26px;
    }
    .constructor,
    .forms {
        flex: 1;
    }

    .body {
        background-color: #f7f9fc;
    }

    .item {
        display: flex;
        align-items: center;
        height: 48px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .multiselect {
        max-width: 400px;
    }

    .footer {
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: end;
        border: 1px solid #d3dbe3;
        border-top: 0;
        padding-right: 40.5%;
    }

    .vertical-line {
        width: 1px;
        background-color: #d3dbe3;
        margin-top: 40px;
    }
}
.add_modal {
    width:100%;
    height:100%;
    &__body {
        background-color: #f7f9fc;
        border: 1px solid #d3dbe3;
        border-radius: 5px;
        margin-bottom: 23px;
    }

    &__title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 50px;
        border: 1px solid #d3dbe3;
        justify-content: space-between;
        padding: 0px 10px 0px 16px;

        &__title {
            font-weight: 500;
            font-size: 14px;
            color: #6087a0;
        }
    }

    .input-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
        &-label {
            width: 400px;
            font-weight: 600;
            font-size: 14px;
            color: #6087a0;
            cursor: pointer;
            display: flex;
            justify-self: flex-start;
        }
    }
    .form-file {
        font-weight: 600;
        font-size: 14px;
        color: #6087a0;
    }
}
.button {
    border: none;
    border-radius: 10px;
}

.button-close {
    color: red;
}
.second-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 26px;
    &__title {
        font-weight: 500;
        font-size: 14px;
    }
}

.date-picker-container {
    display: flex;
    gap: 79px;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6087a0;

    input[type="number"] {
        border: 1px solid #bcd2e0;
        border-radius: 5px;
        padding-right: 5px;
        max-width: 80px;
        height: 28px;
        text-indent: 7px;
        text-align: left;
    }
}

</style>