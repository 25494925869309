























































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITreeData, IAbp, IPrg, IPpr, IMonthVal, IMonthTotal, ISelected } from "./treeData";

const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

@Component({
    name: 'c-prg-tree-table',
    components: {}
})
export default class CPrgTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;

    private collapseAll = true;

    private selectedRow: ISelected | null = null;

    private treeData: ITreeData[] = [];

    private allSum: any = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };
    private mounted() {
        this.getTreeData();
        this.$watch('tableData', () => {
            this.getTreeData();
        }, { deep: true });
    }

    private getTreeData() {
        const treeData: ITreeData[] = [];
        const allSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };
        if (!this.tableData) {
            this.treeData = [];
            return;
        }
        const tableData = this.tableData.slice();
        const grArr: any[] = [];
        for (let i = 0; i < tableData.length; i++) {
            const el  = tableData[i];
            if (el.child_list && el.child_list.length) { el.collapse = this.collapseAll; }
            el._indxArr = i;
        }
        tableData.sort((a: any, b: any) => (a.gr > b.gr) ? 1 : -1);
        for (const el of tableData) {
            if (!grArr.length || grArr[grArr.length-1].gr !== el.gr) {
                grArr.push({ gr: el.gr, grName: el.gr_name,  vals: [el], collapse: this.collapseAll, totalSum: { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 } });
            } else {
                grArr[grArr.length-1].vals.push(el);
            }
        }
        for (const elGr of grArr) {
            elGr.vals.sort((a: any, b: any) => (a.abp > b.abp) ? 1 : -1);
            const resAbp = this.makeTreeArr(elGr.vals, 'abp');
            for (const elAbp of resAbp) {
                elAbp.vals.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                const resPrg = this.makeTreeArr(elAbp.vals, 'prg');
                for (const elPrg of resPrg) {
                    elPrg.vals.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
                    const resPpr = this.makeTreeArr(elPrg.vals, 'ppr');
                    for (const pprEl of resPpr) {
                        pprEl.vals.sort((a: any, b: any) => (a.spf > b.spf) ? 1 : -1);
                    }
                    elPrg.vals = resPpr;
                }
                elAbp.vals = resPrg;
                this.getSumTotal(allSum, elAbp.totalSum);
                this.getSumTotal(elGr.totalSum, elAbp.totalSum);
            }
            elGr.vals = resAbp;
        }
        this.treeData = grArr;
        this.allSum = allSum;
    }
    private makeTreeArr(arr: any[], fieldName: string) {
        const result: any[] = [];
        const totalSum: IMonthTotal = { month0: 0, month1: 0, month2: 0, month3: 0, month4: 0, month5: 0, month6: 0, month7: 0, month8: 0, month9: 0, month10: 0, month11: 0 };
        for (const el of arr) {
            if (!result.length || result[result.length-1][fieldName] !== el[fieldName]) {
                const obj: any = { vals: [el], collapse: this.collapseAll, totalSum: Object.assign({}, totalSum) };
                this.getSumTotal(obj.totalSum, el);
                obj[fieldName] = el[fieldName];
                if (el[`${fieldName}_name`]) {
                    obj[`${fieldName}Name`] = el[`${fieldName}_name`]
                }
                result.push(obj);
            } else {
                this.getSumTotal(result[result.length-1].totalSum, el);
                result[result.length-1].vals.push(el);
            }
        }
        return result;
    }

    private getSumTotal(total: any, obj: IMonthVal) {
        if (obj.month0) { total.month0 = Math.round(((typeof obj.month0 == 'number' ? obj.month0 : parseFloat(obj.month0)) + total.month0)*10)/10; }
        if (obj.month1) { total.month1 = Math.round(((typeof obj.month1 == 'number' ? obj.month1 : parseFloat(obj.month1)) + total.month1)*10)/10; }
        if (obj.month2) { total.month2 = Math.round(((typeof obj.month2 == 'number' ? obj.month2 : parseFloat(obj.month2)) + total.month2)*10)/10; }
        if (obj.month3) { total.month3 = Math.round(((typeof obj.month3 == 'number' ? obj.month3 : parseFloat(obj.month3)) + total.month3)*10)/10; }
        if (obj.month4) { total.month4 = Math.round(((typeof obj.month4 == 'number' ? obj.month4 : parseFloat(obj.month4)) + total.month4)*10)/10; }
        if (obj.month5) { total.month5 = Math.round(((typeof obj.month5 == 'number' ? obj.month5 : parseFloat(obj.month5)) + total.month5)*10)/10; }
        if (obj.month6) { total.month6 = Math.round(((typeof obj.month6 == 'number' ? obj.month6 : parseFloat(obj.month6)) + total.month6)*10)/10; }
        if (obj.month7) { total.month7 = Math.round(((typeof obj.month7 == 'number' ? obj.month7 : parseFloat(obj.month7)) + total.month7)*10)/10; }
        if (obj.month8) { total.month8 = Math.round(((typeof obj.month8 == 'number' ? obj.month8 : parseFloat(obj.month8)) + total.month8)*10)/10; }
        if (obj.month9) { total.month9 = Math.round(((typeof obj.month9 == 'number' ? obj.month9 : parseFloat(obj.month9)) + total.month9)*10)/10; }
        if (obj.month10) { total.month10 = Math.round(((typeof obj.month10 == 'number' ? obj.month10 : parseFloat(obj.month10)) + total.month10)*10)/10; }
        if (obj.month11) { total.month11 = Math.round(((typeof obj.month11 == 'number' ? obj.month11 : parseFloat(obj.month11)) + total.month11)*10)/10; }
    }

    private clkCollapse(grIndx: number | null, abpIndx: number | null, prgIndx: number | null, pprIndx: number | null, spfIndx: number | null, collapse?: boolean) {
        if (grIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.treeData) {
                el.collapse = this.collapseAll;
                for (const abpEl of el.vals) {
                    abpEl.collapse = this.collapseAll;
                    for (const prgEl of abpEl.vals) {
                        prgEl.collapse = this.collapseAll;
                        for (const pprEl of prgEl.vals) {
                            pprEl.collapse = this.collapseAll;
                            for (const spfEl of pprEl.vals) {
                                if (spfEl.collapse !== undefined) {
                                    spfEl.collapse = this.collapseAll;
                                }
                            }
                        }
                    }
                }
            }
            return;
        }
        if (collapse ===undefined) { return; }
        if (abpIndx === null) {
            this.treeData[grIndx].collapse = collapse;
            if (!collapse && this.collapseAll) { this.collapseAll = false; }
            if (collapse !== this.collapseAll) {
                let fl = false;
                for (let i =0; i < this.treeData.length; i++) {
                    if (this.treeData[i].collapse !== collapse) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { this.collapseAll = collapse; }
            }
            for (const abpEl of this.treeData[grIndx].vals) {
                abpEl.collapse = collapse;
                for (const prgEl of abpEl.vals) {
                    prgEl.collapse = collapse;
                    for (const pprEl of prgEl.vals) {
                        pprEl.collapse = collapse;
                        for (const spfEl of pprEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    }
                }
            }
        } else {
            if (prgIndx === null) {
                this.treeData[grIndx].vals[abpIndx].collapse = collapse;
                for (const prgEl of this.treeData[grIndx].vals[abpIndx].vals) {
                    prgEl.collapse = collapse;
                    for (const pprEl of prgEl.vals) {
                        pprEl.collapse = collapse;
                        for (const spfEl of pprEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    }
                }
            } else {
                if (pprIndx === null) {
                    this.treeData[grIndx].vals[abpIndx].vals[prgIndx].collapse = collapse;
                    for (const pprEl of this.treeData[grIndx].vals[abpIndx].vals[prgIndx].vals) {
                        pprEl.collapse = collapse;
                        for (const spfEl of pprEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    }
                } else {
                    if (spfIndx === null) {
                        this.treeData[grIndx].vals[abpIndx].vals[prgIndx].vals[pprIndx].collapse = collapse;
                        for (const spfEl of this.treeData[grIndx].vals[abpIndx].vals[prgIndx].vals[pprIndx].vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    } else {
                        if (this.treeData[grIndx].vals[abpIndx].vals[prgIndx].vals[pprIndx].vals[spfIndx].collapse!==undefined) { this.treeData[grIndx].vals[abpIndx].vals[prgIndx].vals[pprIndx].vals[spfIndx].collapse = collapse; }
                    }
                }
             }
         }
    }
}
