<template>
    <div class="table-container">
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" left toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                  <span class="left">
                      <i class="icon icon-download"></i> Скачать
                  </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item class="text-left" @click="downloadFile('UTOCH_P')">Уточненный план(UTOCH_P)</b-dropdown-item>
            </b-dropdown>
        </div>
        <table class="table b-table table-bordered b-table-no-border-collapse">
            <thead>
            <tr>
                <th>№</th>
                <th>Наименование</th>
                <th>Дата</th>
                <th>Статус</th>
                <th>Комментарий</th>
                <th>Пользователь</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="row of requestsBase">
                <tr :key="row.id" @click="downloadApp(row)" :class="{ 'selected_row': row.selected }" style="cursor: pointer;">
                    <td>{{ row.number }}</td>
                    <td>
                        <a href="#" @click="rowOpen(row)">
                            Уточненный план №{{ row.number}}
                        </a>
                    </td>
                    <td>{{ row.date|formatDate }}</td>
                    <td v-if="('status' in row && row.status !== null)" style="text-align: center;">{{ getLocalStatus(row) }}
                        <span class="doc-icon" v-if="row.parent !== null">
                            <img
                              onerror="this.style.display = 'none'"
                              :src="''"
                              alt="icon"
                              style="width: 20px; height: 20px"
                            />
                        </span>
                    </td>
                    <td v-else></td>
                    <td v-if="row.status">{{ row.status.comment }}</td>
                    <td v-else></td>
                    <td>{{ row.userName }}</td>
                    <td>
                        <b-dropdown v-if="hasEditAccess(row) || hasDeleteAccess(row)" dropleft class="more-dropdown">
                            <template v-slot:button-content>
                                <i class="icon icon-more"></i>
                            </template>
                            <template>
                                <b-dropdown-item-btn v-if="hasEditAccess(row)" @click="() => {onChange(row);}">
                                    <i class="icon icon-pencil-edit"></i>Редактировать
                                </b-dropdown-item-btn>
                                <b-dropdown-item-btn v-if="hasDeleteAccess(row)" @click="() => {onDelete(row);}"
                                ><i class="icon icon-close" style="color: red;"></i>
                                    <span  style="color: red;">  Удалить</span>
                                </b-dropdown-item-btn>
                            </template>
                        </b-dropdown>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
</template>>

<script>
import axios from "axios";

export default {
    name: "UtochRequestTable",
    props: ["requestsBase", "accessLevels", "statusType"],
    data() {
        return {
            levelsCheck: true,
            selectedAlteration: null
        }
    },
    methods: {
        hasEditAccess(row) {
            return this.accessLevels.position === 'uf' && (row.hasOwnProperty('status') && ([1, 27].includes(row.status.status)))
        },
        hasDeleteAccess(row) {
            return this.accessLevels.position === 'uf' && (row.hasOwnProperty('status') && ([1].includes(row.status.status)))
        },
        onOpen(item){
            this.$emit('onOpen', item);
        },
        rowOpen(row) {
            this.onOpen(row);
        },
        onChange(item) {
            this.$emit('onChange', item)
        },
        onDelete(item) {
            this.$emit('onDelete', item)
        },
        getLocalStatus(value) {
            const status = value.status.status;
            if (!this.statusType || !this.statusType.length) return status;
            const curStatus = this.statusType.filter((item) => item.code === status);
            if (!curStatus || !curStatus.length) return status;
            const localName = curStatus[0]["name_" + this.$i18n.locale];
            return localName;
        },
        downloadApp(item) {
            for (const r of this.requestsBase) {
                r.selected = false;
            }
            this.selectedAlteration = item;
            item.selected = !item.selected;
            item.id += 1;
            item.id -= 1;
        },
        async downloadFile(app) {
            try {
                const item = this.requestsBase.find(x => x.selected);
                const response = await axios.get(`api/budget-execution-base/xls?type=PAYMENT&requestId=${item.id}&level=UF&dataLevel=PRG&addIpf=true&addAllAgreed=true&code=${app}`, {responseType: 'blob'})

                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/xls'}));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', `${app}`+ '.xls');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.error('Произошла ошибка при загруке файла', error.toString());
                this.toast('danger', this.i18n.translate('error.cannot_load_files'), `Уточненный план не найден`)
            }
        },
    },
    filters: {
        formatDate: function (value) {
            if (value) {
                const date = new Date(value);
                return date.toLocaleDateString();
            }
            return null;
        }
    }
}
</script>

<style scoped>

</style>