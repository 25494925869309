









import { Component, Vue, Prop } from 'vue-property-decorator';
import CPrgTreeTable from '@/modules/budget/alteration/form/tabs/components/CreateForm/SpfTreeTables/CPrgTreeTable.vue';
import CGuTreeTable from '@/modules/budget/alteration/form/tabs/components/CreateForm/SpfTreeTables/CGuTreeTable.vue';
import CAbpGuTreeTable from '@/modules/budget/alteration/form/tabs/components/CreateForm/SpfTreeTables/CAbpGuTreeTable.vue';

@Component({
    name: 'c-spf-tree-table',
    components: {
        'c-prg-tree-table': CPrgTreeTable,
        'c-gu-tree-table': CGuTreeTable,
        'c-abp-gu-tree-table': CAbpGuTreeTable
 }
})
export default class CSpfTreeTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: []
    })
    private editedMonths!: number[]; // 0-11

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: any;

    @Prop({
        required: true,
        default: null
    })
    private uSumVisible!: number;

    @Prop({
        required: false,
        default: 0
    })
    private tableType!: number;

    @Prop({
        required: false,
        default: ''
    })
    private flc2!: any;

    @Prop({
        required: false,
        default: ''
    })
    private flc4!: any;

    private chgData(data: any) {
        this.$emit('chgData', data);
    }
    private agrBtn(val: any) {
        this.$emit('agrBtn', val);
    }

    private treeData: any[] = [];

    private tree(value: any) {
        this.treeData = value
    }
}

