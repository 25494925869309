<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------date------------------------->
                            <div class="filter-block">
                                <b-form-group label="Дата">
                                    <date-picker
                                        v-model="currentDate"
                                        format = "DD/MM/YYYY"
                                        type="date"
                                        ref="filterDate"
                                        disabled=""
                                    >
                                    </date-picker>
                                </b-form-group>
                            </div>
                            <!--АБП-->
                            <div class="filter-block" v-if="reqType === 1">
                                <b-form-group label="АБП">
                                    <multiselect
                                        v-model="currAbp"
                                        label="localText"
                                        placeholder="Выберите АБП"
                                        :options="abpBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="loadCompany"
                                        ref="filterAbp"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block"  v-if="reqType === 1 && ['abp'].includes(accessLevels.position)">
                                <b-form-group  label="Подведомственные ГУ">
                                    <div class="modal__toggle">
                                        <b-form-checkbox v-model="checkedGU" switch/>
                                    </div>
                                </b-form-group>
                            </div>
                            <!--ГУ-->
                            <div class="filter-block" v-if="reqType === 1 && ((checkedGU && ['abp'].includes(accessLevels.position)) || !['abp'].includes(accessLevels.position))">
                                <b-form-group label="ГУ">
                                    <multiselect
                                        v-model="currGu"
                                        label="localText"
                                        placeholder="Выберите ГУ"
                                        :options="guBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="guChange"
                                        ref="filterGu"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--Регион-->
                            <div class="filter-block">
                                <b-form-group label="Регион">
                                    <multiselect
                                        v-model="curRegion"
                                        placeholder="Выберите регион"
                                        label="localText"
                                        :options="regionBase"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        disabled=""
                                        ref="filterRegion"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--вид заявок-->
                            <div class="filter-block" v-if="reqType === 1">
                                <b-form-group label="Вид данных">
                                    <label for=""><input @input="loadingArrData" type="radio" v-model="tabType" :value="true">Обязательства</label>
                                    <label for=""><input @input="loadingArrData" type="radio" v-model="tabType" :value="false">Платежи</label>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-form-group>
                                    <b-button @click="loadingArrData">Применить</b-button>
                                </b-form-group>
                            </div>

                        </div>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <b-button variant="secondary" @click="onBack">Назад к списку</b-button>
                </div>
            </div>
        </div>
        <div v-if="accessLevels.position === 'uf'" class="actions-tab in-tab">
            <!---------------Форма для скачивания отчета--------------->
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="downloadReport">{{`Уточненный план(${reqType == 1? 'расходы' :'доходы'})`}}</b-dropdown-item>
            </b-dropdown>
        </div>
        <!--  хлебные крошки   -->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" @click="openFilterByRef('filterDate')">{{ refDate }}</span>
            <span class="item-breadcrumb" @click="openFilterByRef('filterRegion')">{{ curRegion.localText }}</span>
        </div>
        <template v-if="reqType === 1">
            <div>
                <b-tabs class="tabs-in-tabs">
                    <b-tab>
                        <template #title>
                            <button class="icon-btn" @click="toggleShowObligation">
                                <i class="icon icon-keyboard icon-rotate-270" v-if="showObligation"></i>
                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                            </button>
                            <span class="ml-2">{{ tabType? 'Обязательства': 'Платежи' }}</span>
                        </template>
                        <div class="top-table-line"></div>
                        <utoch-expenses
                        :table-data="expensesArrData"
                        :tab-type="tabType"
                        :table-level="tableLevel"
                        ></utoch-expenses>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
        <template v-if="reqType === 2">
            <income-table
            :table-data="incomeArrData"
            ></income-table>
        </template>
    </div>
</template>

<script>
import UtochExpenses from '@/modules/budget/alteration/form/tabs/components/utochPlan/UtochExpenses.vue';
import incomeTable from '@/modules/budget/alteration/form/tabs/components/utochPlan/tables/incomeTable';
import DatePicker from "vue2-datepicker";
import axios from 'axios'
import { Ax } from "@/utils";
import store from "@/services/store";
const tabLevels = {
    ipf: 0,
    abp: 1,
    uf: 2
};
const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
export default {
    name: "UtochPlanForm",
    components: { UtochExpenses, incomeTable, DatePicker },
    props: ["currentUtochPlan", "reqType", "curRegion", "regionBase", "accessLevels"],
    data() {
        return {
            month: 0,
            userId: store.state.user.sub,
            currGu: null,
            currAbp: null,
            abpBase: [],
            guBase: [],
            checkedGU: false,

            tabType: true,
            currentDate: null,
            currentMonth: 0,

            showObligation: true,
            showPayment: true,

            expensesArrData: [],
            incomeArrData: []

        }
    },
    async created() {
        this.currentDate = new Date(this.currentUtochPlan.date)
        this.month = this.currentDate.getMonth()
    },
    async mounted() {
        await this.loadingArrData()
        await this.loadAbpList()
    },
    computed: {
        refDate() {
            const date = new Date(this.currentDate);
            return date.toLocaleDateString();
        },
        tableLevel() {
            return tabLevels[this.accessLevels.position];
        }

    },
    methods: {
        downloadReport() {
            const params = {
                tabType: false,
                utochId: this.currentUtochPlan.id,
                utochPlan: this.currentUtochPlan,
                reqType: this.reqType
            }
            const levelNum = this.tableLevel
            if (this.reqType === 1) {
                params.tabelLevel = this.tableLevel
                if (levelNum < 2 && this.currAbp) {
                    params['abp'] = this.currAbp.abp
                    if ((levelNum === 0 || this.checkedGU) && this.currGu) {
                        params['gu'] = this.currGu.code
                    }
                } else if (levelNum === 2) {
                    if (this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
                        params['abp'] = this.currAbp.abp
                    }
                    if (this.currGu && this.currGu.hasOwnProperty('abp') && this.currGu.code) {
                        params['gu'] = this.currGu.code
                    }
                }
            }

            const path = `/api-py/utoch-report/${JSON.stringify(params)}`;
            Ax(
                {
                    url: path,
                    method: 'POST',
                    data: null,
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Приложение_42(${this.reqType == 1? 'Расходы' :'Доходы'}).xls`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    console.log('err', error);
                }
            );
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },
        onBack() {
            this.$emit('onBack', false);
        },
        toggleShowObligation() {
            this.showObligation = !this.showObligation;
        },
        toggleShowPayment() {
            this.showPayment = !this.showPayment;
        },
        async loadingArrData() {
            if (this.reqType === 1) {
                await this.loadingArrDataExpense()
            } else {
                await this.loadingArrDataIncome()
            }
        },
        async loadingArrDataExpense () {
            const levelDict = {0: 'GU', 1: 'ABP', 2: 'UF'}
            const levelNum = this.tableLevel
            let path = `/api/budget-execution-base/data?planType=EXPENSE&requestId=${this.currentUtochPlan.id}&type=${this.tabType? 'OBLIGATION': 'PAYMENT'}&level=${levelDict[levelNum]}`
            if (levelNum < 2 && this.currAbp) {
                path += `&abp=${this.currAbp.abp}`
                if ((levelNum === 0 || this.checkedGU) && this.currGu) {
                    path += `&gu=${this.currGu.code}`
                }
            } else if (levelNum === 2) {
                if (this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
                    path += `&abp=${this.currAbp.abp}`
                    if (this.currGu && this.currGu.hasOwnProperty('gu') && this.currGu.code) {
                        path += `&gu=${this.currGu.code}`
                    }
                }
            }

            this.expensesArrData = []
            await axios.get(path)
                .then(resp => {
                    const arrData = resp.data
                    for (const el of arrData) {
                        for (let i = 0; i < 12; i++) {
                            if (i !== this.month) {
                                el[monthLst[i]] = 0
                            }
                        }
                        el.plan = el[monthLst[this.month]]
                    }
                    arrData.sort((a, b) => {
                        if (this.tableLevel === 2 && a.abp !== b.abp) {
                            return a.abp - b.abp;
                        }
                        if (this.tableLevel === 1 && a.gu !== b.gu) {
                            return a.gu - b.gu;
                        }
                        if (a.prg !== b.prg) {
                            return a.prg - b.prg;
                        }
                        if (a.ppr !== b.ppr) {
                            return a.ppr - b.ppr;
                        }
                        return a.spf - b.spf;
                    })

                    this.expensesArrData = arrData
                }).catch(error => {})
        },

        async loadingArrDataIncome () {
            const path = `/api/budget-execution-base/data?planType=INCOME&requestId=${this.currentUtochPlan.id}`

            this.incomeArrData = []
            await axios.get(path)
                .then(resp => {
                const arrData = resp.data;
                for (const el of arrData) {
                    for (let i = 0; i < 12; i++) {
                        if (i !== this.month) {
                            el[monthLst[i]] = 0
                        }
                    }
                    el.plan = el[monthLst[this.month]]
                }
                arrData.sort((a, b) => {
                        if (a.kat !== b.kat) {
                            return a.kat - b.kat;
                        }
                        if (a.cls !== b.cls) {
                            return a.cls - b.cls;
                        }
                        if (a.pcl !== b.pcl) {
                            return a.pcl - b.pcl;
                        }
                        return a.spf - b.spf;
                    })
                this.incomeArrData = arrData
            }).catch(error => {})
        },




        async loadAbpList() {
            let response = [];
            try {
                response = await fetch('/api-py/user-abp-list/' + this.userId);
                const newBase = await response.json();
                if (newBase.length && this.accessLevels.position === 'uf') newBase.splice(0, 0, {abp: null})
                this.abpBase = this.setIdArr(newBase, "abp");
                if (this.abpBase.length > 0) {
                    this.abpBase
                        .sort((a, b) => (a.abp - b.abp > 0 ? 1 : -1))
                        .map((item) => {
                            item.localText = item.abp? `${item.abp} - ${item["name_" + this.$i18n.locale]}`: 'ВСЕ';
                            return item;
                        });
                    this.currAbp = this.abpBase[0];
                    await this.loadCompany();
                }
            } catch (error) {
                response = [];
                this.makeToast("danger", "Ошибка загрузки АБП222", error.toString());
            }
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (
                    result.length > 0 &&
                    el[codeName] === result[result.length - 1][codeName]
                ) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        async loadCompany() {
            let result = [];
            this.curComp = null;
            if (this.currAbp && this.currAbp.hasOwnProperty('abp') && this.currAbp.abp) {
                try {
                    result = await fetch(`/api-py/get-gu-by-abp-user-id/${this.currAbp.abp}/${this.userId}`)
                        .then(response => response.json());
                } catch (error) {
                    result = [];
                    this.makeToast('danger', 'Ошибка загрузки гос. учреждений222', error.toString());
                }
                if (result.length > 0) {
                    if (['uf'].includes(this.accessLevels.position)) result.splice(0, 0, {code: null})
                    this.curComp = this.setNameLang(result[0], 'code');
                    // await this.getRegionByCodeGu();
                }
                this.guBase = result;
                this.guBase.sort((a, b) => (a.code - b.code > 0 ? 1 : -1))
                    .map((item) => {
                        item.localText = item.code? `${item.code} - ${item["name_" + this.$i18n.locale]}`: 'ВСЕ';
                        return item;
                    });
                this.currGu = this.guBase[0];
            }
        },
        async guChange() {
            if (this.currGu && this.currGu.code) {
                this.curComp = this.setNameLang(this.findElByCode('code', this.currGu.code, this.guBase), 'code');
                // await this.getRegionByCodeGu();

            } else {
                this.curComp = this.setNameLang(this.guBase[0], 'code');
            }
        },
        setNameLang(obj, codeName) {
            if (obj === null) { return null; }
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        findElByCode(codeName, code, arr) {
            if (code === null) { return null; }
            for (const el of arr) {
                if (el[codeName] === code) {
                    return el;
                }
            }
            return null;
        },
    }
}
</script>

<style lang="scss" scoped>
.icon-btn{
    width: 20px;
    height: 20px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid rgba(28, 42, 62, 1);
    border-radius: 50%;
    margin: 0;
}
.icon-btn i{
    font-size: 0.4444444444rem;
    color: rgba(28, 42, 62, 1);
}
.duplicate-button{
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 20px;
}
</style>