



























import {Component, Prop, Vue} from "vue-property-decorator";
import axios from 'axios'
import * as XLSX from 'xlsx'
import {cloneWith} from "lodash";

@Component({
    components: {}
})
export default class SpfImportFromFileModal  extends Vue {
    @Prop({
        required: false,
        default: null
    })
    private curAbp!: any;
    @Prop({
        required: false,
        default: null
    })
    private curGu!: any;
    @Prop({
        required: false,
        default: null
    })
    private curRegion!: any;
    @Prop({
        required: false,
        default: null
    })
    private curMonth!: any;

    @Prop({
        required: false,
        default: false
    })
    private show!: any;
    @Prop({
        required: false,
        default: 1
    })
    private tableType!: any;

    @Prop({
        required: false,
        default: 0
    })
    private requestId!: any;
    private curentRequest: any = {};

    private tableTypeLocal: any = 1
    private selectedFile: any = null;
    private tableData: any[] = []
    private importedData: any[] = []
    private tableFields: any[] = [
        {label: "АБП", key: "abp"},
        {label: "ГУ", key: "gu"},
        {
            key: 'prg',
            label: 'ПРОГРАММА'
        },
        {
            key: 'ppr',
            label: 'ПОДПРОГРАММА'
        },
        {
            key: 'spf',
            label: 'СПЕЦИФИКА'
        },
        {label: "Cумма", key: "total"},
        {
            key: 'january',
            label: 'Январь'
        },
        {
            key: 'february',
            label: 'Февраль'
        },
        {
            key: 'march',
            label: 'Март'
        },
        {
            key: 'april',
            label: 'Апрель'
        },
        {
            key: 'may',
            label: 'Май'
        },
        {
            key: 'june',
            label: 'Июнь'
        },
        {
            key: 'july',
            label: 'Июль'
        },
        {
            key: 'august',
            label: 'Август'
        },
        {
            key: 'september',
            label: 'Сентябрь'
        },
        {
            key: 'october',
            label: 'Октябрь'
        },
        {
            key: 'november',
            label: 'Ноябрь'
        },
        {
            key: 'december',
            label: 'Декабрь'
        },
    ];

    private calcFields = [
        'plan',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ]
    private showModal = false;
    private created() {
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        })
        this.$watch('tableType', function () {
            that.tableTypeLocal = that.tableType
        });
        this.$watch('requestId', async function () {
            await this.updateRequest()
        });
    }
    private mounted() {}
    private importFiles() {
        const resObj = {
            fact: this.tableData,
            plan: this.tableData
        }
        if (this.tableTypeLocal === 1) {
            this.$emit('importTableObligations', resObj)
        } else {
            this.$emit('importTablePayments', resObj)
        }
        this.showModal = false;
        this.tableData = [];
        this.importedData = []
    }
    private async loadSpfDict(spfs: any[]) {
        const req = {
            abp: this.curAbp.abp,
            spf_list: spfs
        }
        let dict:any = {}
        await axios.post('/api-py/dict-ebk-spf-map', req).then(response => {
            dict = response.data
        })
        return dict
    }
    private parseFloatZero(input:any) {
        const rowN = input? input.toString().replace(',', '.'): 0
        const floatValue = parseFloat(rowN);
        return Number.isNaN(floatValue) ? 0 : floatValue;
    }
    private async updateRequest() {
        await axios.get(`/api-py/import-file-request-id/${this.requestId}`).then(response => {
            this.curentRequest = response.data
        }).catch(error => {
            console.log(error)
            this.curentRequest = null
        })
    }
    private async getTalbeData() {
        const isRowEmpty = (row: any[]) => row.every(cell => cell === '' || cell === null || isNaN(cell));
        const spfs = []
        const preData = [];
        await this.updateRequest()
        for (const row of this.importedData) {
            if (isRowEmpty(row.slice(0, 4))) continue;
            try {
                const region = (this.curentRequest && this.curentRequest.region)? this.curentRequest.region: row[0]
                const apbPrgPpr:string = row[1].toString()
                const abp = (this.curentRequest && this.curentRequest.abp)? this.curentRequest.abp: parseInt(apbPrgPpr.slice(0, 3), 10)
                const prg = parseInt(apbPrgPpr.slice(3, 6), 10)
                const ppr = parseInt(apbPrgPpr.slice(6), 10)
                const spf = parseInt(row[2].toString())
                const gu = row[3].toString()
                const total = this.parseFloatZero(row[4])
                if (gu !== this.curGu.code.toString()) continue;
                const spfObj = {
                    region: region,
                    abp: abp,
                    abp_name: '',
                    gu: gu,
                    gu_name: '',
                    prg: prg,
                    prg_name: '',
                    ppr: ppr,
                    ppr_name: '',
                    spf: spf,
                    name_ru: '',
                    plan: total,
                    plan_type: 1,
                    total: total,
                    month: this.curMonth,
                    gr: null
                }
                preData.push(spfObj)
                spfs.push({
                    spf: spf,
                    prg: prg,
                    ppr: ppr
                })
                for (let i = 5; i < 17; i++) {
                    if (i-4 <this.curMonth) {
                        this.$set(spfObj, this.calcFields[i-5+1], 0);
                        this.$set(spfObj, 'month' + (i-5), 0);
                    } else {
                        const value = this.parseFloatZero(row[i])
                        this.$set(spfObj, this.calcFields[i-5+1], value);
                        this.$set(spfObj, 'month' + (i-5), value);
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        if (preData.length === 0) {
            this.makeToast('warning', 'Импорт Таблицы', 'ГУ в выбранном Вами файле не соответствует выбранному ГУ в системе')
            return
        }
        const spfDict:any = await this.loadSpfDict(spfs)
        if (Object.keys(spfDict).length === 0) {
            this.makeToast('warning', 'Импорт Таблицы', 'Программа или Подрограмма в выбранном Вами файле не соответствуют Данному Абп')
            return
        }
        const locale_i18 = 'name_' + this.$i18n.locale
        const newTable: any[] = [];

        for (let i = 0; i < preData.length; i++) {
            const new_item = Object.assign({}, preData[i])
            if (!spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]) {
                continue;
            }
            new_item.prg_name = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['prg_' + locale_i18]
            new_item.ppr_name = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr_' + locale_i18]
            new_item.name_ru = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`][locale_i18]
            new_item.gr = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['gr']
            new_item.ppr = spfDict[`${new_item.spf}_${new_item.prg}_${new_item.ppr}`]['ppr']
            newTable.push(new_item)
        }
        this.tableData = newTable
        if (this.tableData.length === 0) {
            this.makeToast('warning', 'Импорт Таблицы', 'ГУ в выбранном Вами файле не соответствует выбранному ГУ в системе')
        }
    }
    private async handleFileInput (event:any) {
        this.tableData = []
        this.importedData = []
        this.selectedFile = null
        // Access the selected file from the event
        this.selectedFile = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e: any) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: "binary"});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});
            this.importedData = data.slice(1)
            await this.getTalbeData()
        };
        reader.readAsBinaryString(this.selectedFile);

    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

}
