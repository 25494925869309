











































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CPopUp from "../CPopUp.vue";

const monthLst = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

@Component({
    name: 'c-abp-gu-tree-table',
    components: { 'c-pop-up': CPopUp }
})
export default class CAbpGuTreeTable extends Vue {
    private range(n: number) {
        return [...Array(n).keys()];
    }
    private monthLst: any[] = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;
    private treeData: any[] = [];
    private uSumVisible: any = 2;
    private totalData = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
    }

    private totalTree() {
        const totalData: any = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0
        }
        for (const treEl of this.treeData) {
            for (let i = 0; i < 12; i++) {
                totalData[monthLst[i]] += treEl[monthLst[i]]
            }
        }
        this.totalData = totalData
    }

    private collapseAll = true;
    private clkCollapse(abpIndx: number | null, guIndx: number | null, prgIndx: number | null, subIndx: number | null, spfIndx: number | null, collapse?: boolean) {
        if (abpIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const abpEl of this.treeData) {
                abpEl.collapse = this.collapseAll;
                for (const guEl of abpEl.vals) {
                    guEl.collapse = this.collapseAll;
                    for (const el of guEl.vals) {
                        el.collapse = this.collapseAll;
                        for (const subEl of el.vals) {
                            subEl.collapse = this.collapseAll;
                            for (const spfEl of subEl.vals) {
                                if (spfEl.collapse!==undefined) { spfEl.collapse = this.collapseAll; }
                            }
                        }
                    }
                }
            }
            return;
        }

        if (collapse ===undefined) { return; }

        if (guIndx === null) {
            this.treeData[abpIndx].collapse = collapse;
            if (!collapse && this.collapseAll) { this.collapseAll = false; }
            if (collapse !== this.collapseAll) {
                let fl = false;
                for (let i = 0; i < this.treeData.length; i++) {
                    if (this.treeData[i].collapse !== collapse) {
                        fl = true;
                        break;
                    }
                }
                if (!fl) { this.collapseAll = collapse; }
            }
            for (const guEl of this.treeData[abpIndx].vals) {
                guEl.collapse = collapse;
                for (const prgEl of guEl.vals) {
                    prgEl.collapse = collapse;
                    for (const subEl of prgEl.vals) {
                        subEl.collapse = collapse;
                        for (const spfEl of subEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    }
                }
            }
        } else {
            if (prgIndx === null) {
                this.treeData[abpIndx].vals[guIndx].collapse = collapse;
                for (const prgEl of this.treeData[abpIndx].vals[guIndx].vals) {
                    prgEl.collapse = collapse;
                    for (const subEl of prgEl.vals) {
                        subEl.collapse = collapse;
                        for (const spfEl of subEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    }
                }
            } else {
                if (subIndx === null) {
                    this.treeData[abpIndx].vals[guIndx].vals[prgIndx].collapse = collapse;
                    for (const subEl of this.treeData[abpIndx].vals[guIndx].vals[prgIndx].vals) {
                        subEl.collapse = collapse;
                        for (const spfEl of subEl.vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    }
                } else {
                    if (spfIndx === null) {
                        this.treeData[abpIndx].vals[guIndx].vals[prgIndx].vals[subIndx].collapse = collapse;
                        for (const spfEl of this.treeData[abpIndx].vals[guIndx].vals[prgIndx].vals[subIndx].vals) {
                            if (spfEl.collapse!==undefined) { spfEl.collapse = collapse; }
                        }
                    } else {
                        if (this.treeData[abpIndx].vals[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].collapse!==undefined) { this.treeData[abpIndx].vals[guIndx].vals[prgIndx].vals[subIndx].vals[spfIndx].collapse = collapse; }
                    }
                }
            }
        }
    }

    private mounted() {
        this.getTreeData();
        this.$watch('tableData', () => {
            this.getTreeData();
        }, { deep: true });
    }

    private sortNodes(nodes: any[], codeName: any) {
        return nodes.sort((a, b) => {
            if (a[codeName] < b[codeName]) return -1;
            if (a[codeName] > b[codeName]) return 1;
            return 0;
        });
    };
    private treeCodes: any[] = ['abp', 'gu', 'prg', 'ppr', 'spf']
    private sortTree(tree: any, depth: number = 0) {
        if (tree.vals) {
            tree.vals = this.sortNodes(tree.vals, this.treeCodes[depth]);
            for (const childNode of tree.vals) {
                this.sortTree(childNode, depth + 1);
            }
        }
    };
    private getTreeData() {
        if (!this.tableData) {
            this.treeData = [];
            return;
        }
        const tableData = this.tableData.slice();
        const abpMap: any = {}
        let _indexArrC = 0;
        for (const item of tableData) {
            item._indxArr = _indexArrC

            const abp = item.abp
            const gu = item.gu
            const prg = item.prg
            const ppr = item.ppr
            const spf = item.spf
            const plan = item.plan

            let abpNode: any = null;
            if (!(abp in abpMap)) {
                abpNode = Object.assign({collapse: this.collapseAll, vals: []}, item)
                abpMap[abp] = abpNode
            } else {
                abpNode = abpMap[abp]
                abpNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    abpNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let guNode: any = abpNode.vals.find((node: any) => node.gu === gu)
            if (!guNode) {
                guNode = Object.assign({collapse: this.collapseAll, vals: []}, item)
                abpNode.vals.push(guNode)
            } else {
                guNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    guNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let prgNode: any = guNode.vals.find((node: any) => node.prg === prg)
            if (!prgNode) {
                prgNode = Object.assign({collapse: this.collapseAll, vals: []}, item)
                guNode.vals.push(prgNode)
            } else {
                prgNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    prgNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let pprNode: any = prgNode.vals.find((node: any) => node.ppr === ppr)
            if (!pprNode) {
                pprNode = Object.assign({collapse: this.collapseAll, vals: []}, item)
                prgNode.vals.push(pprNode)
            } else {
                pprNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    pprNode[monthLst[i]] += item[monthLst[i]]
                }
            }
            let spfNode: any = pprNode.vals.find((node: any) => node.spf === spf)
            if (!spfNode) {
                spfNode = Object.assign({}, item)
                pprNode.vals.push(spfNode)
            } else {
                spfNode.plan += plan
                for (let i = 0; i < 12; i++) {
                    spfNode[monthLst[i]] += item[monthLst[i]]
                }
            }

            _indexArrC++;
        }

        this.treeData = Object.values(abpMap)

        this.totalTree()
    }

    private totalSum(el: any) {
        let total = 0;
        for (let i = 0; i < 12; i++) {
            total += el[monthLst[i]];

        }
        return total
    }
}
