<template>
  <div class="table-container">
      <div class="actions-tab in-tab">
          <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                  <div class="btn-download">
                      <div>
                  <span class="left">
                      <i class="icon icon-download"></i> Скачать
                  </span>
                          <i class="icon icon-keyboard"></i>
                      </div>
                  </div>
              </template>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && !activeTab" @click="downloadApplication(9)">Приложение 25 - Справка о внесении изменений в СПП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && !activeTab" @click="downloadApplication(10)">Приложение 26 - Справка о внесении изменений в СПП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(2)">Приложение 27 - Заявка на изменение ИПФ ГУ по обязательствам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.001' && activeTab === 'uf'" @click="downloadApplication(1)">Приложение 28 - Заявка на изменение ИПФ ГУ по платежам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && accessLevels.position === 'abp'" @click="downloadApplication(5)">Приложение 29 - Заявка на изменение ПФ по обязательствам АБП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && accessLevels.position === 'abp'" @click="downloadApplication(6)">Приложение 30 - Заявка на изменение ПФ по платежам АБП</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.001' && accessLevels.position === 'abp' || selectedAlteration && accessLevels.modules === '005.002.002' && accessLevels.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(3)">Приложение 34 - Справка о внесении изменений в ИПФ по обязательствам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.001' && accessLevels.position === 'abp' || selectedAlteration && accessLevels.modules === '005.002.002' && accessLevels.position === 'uf' && activeTab === 'abp'" @click="downloadApplication(4)">Приложение 36 - Справка о внесении изменений в ИПФ по платежам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && activeTab === 'uf'" @click="downloadApplication(7)">Приложение 37 - Справка о внесении изменений в СПФ по платежам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && activeTab === 'uf'" @click="downloadApplication(8)">Приложение 39 - Справка о внесении изменений в СПФ по обязательствам</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && ((['005.002.002'].includes(accessLevels.modules) && ['abp'].includes(activeTab) ) || ['005.002.001'].includes(accessLevels.modules) && ['ipf'].includes(activeTab)) && accessLevels.position === 'abp'" @click="downloadFile('SVI_IPF_PO')">СВИ ИПФ (обязательства)</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && ((['005.002.002'].includes(accessLevels.modules) && ['abp'].includes(activeTab) ) || ['005.002.001'].includes(accessLevels.modules) && ['ipf'].includes(activeTab)) && accessLevels.position === 'abp'" @click="downloadFile('SVI_IPF_PP')">СВИ ИПФ (платежи)</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && activeTab === 'uf'" @click="downloadFile('SVI_SPF_PO')">СВИ СПФ (обязательства)</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && activeTab === 'uf'" @click="downloadFile('SVI_SPF_PP')">СВИ СПФ (платежи)</b-dropdown-item>
             <b-dropdown-item class="text-left" v-if="selectedAlteration && accessLevels.modules === '005.002.002' && !activeTab" @click="downloadFile('SVI_SP_PB')">СВИ СПП</b-dropdown-item>
          </b-dropdown>
          <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                  <div class="btn-download">
                      <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                          <i class="icon icon-keyboard"></i>
                      </div>
                  </div>
              </template>
              <b-dropdown-text>
                  <div class="info-button" v-if="accessLevels.position === 'ipf'">
                        <span @click="infoModule(30, 'Инструкция пользователя Заявки ГУ')">Раздел «Заявки ГУ»<i
                            class="icon icon-folder"></i></span>
                  </div>
<!--                  <div class="info-button" v-if="accessLevels.position === 'abp'">-->
<!--                        <span @click="infoModule(30, 'Инструкция пользователя Заявки ГУ')">Раздел «Заявки АБП»<i-->
<!--                            class="icon icon-folder"></i></span>-->
<!--                  </div>-->
<!--                  <div class="info-button" v-if="accessLevels.position === 'uf'">-->
<!--                        <span @click="infoModule(30, 'Инструкция пользователя Заявки ГУ')">Раздел «Заявки УО»<i-->
<!--                            class="icon icon-folder"></i></span>-->
<!--                  </div>-->
                  <div class="info-button"  v-if="accessLevels.position === 'abp'">
                      <a href="https://www.youtube.com/watch?v=EI7RzAaW6_c&ab_channel=eAkimat365" target="_blank"><span>Видеоинструкция "Заполнение заявок ГУ" <i
                          class="icon icon-video"></i></span></a>
                      <a href="https://www.youtube.com/watch?v=lFoY3fKLUVk&ab_channel=eAkimat365" target="_blank"><span>Видеоинструкция "Импорт шаблона" <i
                          class="icon icon-video"></i></span></a>
                      <a href="https://www.youtube.com/watch?v=f3Wqet3K6Ck&ab_channel=eAkimat365" target="_blank"><span>Видеоинструкция "АБП согласование корректировок, уточнений, передвижек" <i
                          class="icon icon-video"></i></span></a>
                      <a href="https://www.youtube.com/watch?v=IcYjD_b5zW0&ab_channel=eAkimat365" target="_blank"><span>Видеоинструкция "АБП Согласование внутренних заявок" <i
                          class="icon icon-video"></i></span></a>
                  </div>

                  <div class="info-button" v-if="accessLevels.position === 'uf'">
                      <a href="https://www.youtube.com/watch?v=0Cm0Ad26CPw&ab_channel=eAkimat365" target="_blank"><span>Видеоинструкция "Согласование заявок УО" <i
                              class="icon icon-video"></i></span></a>
                  </div>
                  <div class="info-text" v-if="accessLevels.position === 'ipf'">
                      <p>Данный режим предназначен для формирования государственным учреждением заявки по внесению изменений
                          в индивидуальные планы финансирования по обязательствам и платежам и направления ее
                          для утверждения администратору бюджетных программ согласно приказу №540 МФ РК
                          «Об утверждении Правил исполнения бюджета и его кассового обслуживания».</p>
                  </div>
              </b-dropdown-text>
          </b-dropdown>
      </div>
    <table class="table b-table table-bordered b-table-no-border-collapse">
      <thead>
        <tr>
          <th>№</th>
          <th v-if="accessLevels !== undefined && ['abp', 'uf'].includes(accessLevels.position) && levelsCheck && checkCount > 0">
              <div class="text-center">
                  <b-form-checkbox
                      v-if="checkInVisible"
                      v-model="selectAll"
                      @change="e => setIsAllDelete(e)"
                      :value="true"
                      :unchecked-value="false" />
              </div>
          </th>
          <th>Наименование</th>
          <th>Дата</th>
          <th>Статус</th>
          <th>Основание</th>
          <th>Комментарий</th>
          <th>Пользователь</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row of requestsBase">
          <tr :key="row.id" @click="downloadApp(row)" :class="{ 'selected_row': row.selected }" style="cursor: pointer;">
            <td>{{ row.number }}</td>
            <td v-if="['abp', 'uf'].includes(accessLevels.position) && levelsCheck && checkCount > 0"><b-form-checkbox v-if="row.checking" v-model="row.check" @change="onChecked($event)"
                                                                                                     @input="e => { onInput(e) }"></b-form-checkbox></td>
            <td>
              <a href="#" @click="rowOpen(row)">
                  {{ getFullName(row) }}
              </a>
            </td>
            <td>{{ row.date | formatDate }}</td>
            <td v-if="('status' in row && row.status !== null)" style="text-align: center;">{{ getLocalStatus(row) }}
                <span class="doc-icon" v-if="row.parent !== null">
                      <img
                          onerror="this.style.display = 'none'"
                          :src="getIcon()"
                          alt="icon"
                          style="width: 20px; height: 20px"
                      />
                  </span>
            </td>
            <td v-else></td>
            <td>
              <div>{{ row.description }}</div>
              <div v-for="file of row.fileList" :key="file.id">
                <a href="#" @click="() => downloadLink(file)">
                  <span class="doc-icon">
                      <img
                        onerror="this.style.display = 'none'"
                        :src="getLink(file.name)"
                        alt="icon"
                      />
                  </span>
                  {{ file.name }}
                </a>
              </div>
            </td>
            <td v-if="row.status">{{ row.status.comment }}</td>
            <td v-else></td>
            <td>{{ row.userName }}</td>
            <td>
              <b-dropdown dropleft class="more-dropdown">
                <template v-slot:button-content>
                  <i class="icon icon-more"></i>
                </template>
                <template>
                  <b-dropdown-item-btn @click="() => {onChange(row);}"
                       v-if="haveEditAccess(row)">
                      <i class="icon icon-pencil-edit"></i>Редактировать
                  </b-dropdown-item-btn>
                  <b-dropdown-item-btn @click="() => {onDelete(row);}"
                       v-if="haveDeleteAccess(row)"
                  ><i class="icon icon-close" style="color: red;"></i>
                      <span  style="color: red;">  Удалить</span>
                  </b-dropdown-item-btn>
                </template>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>


<script>
import {Ax} from "@/utils";
import axios from "axios";
import {makeToast} from "@/modules/budget/bip/bip-types";
export default {
  data() {
    return {
      check: false,
      selectedAlteration: null,
      checkButton: 0,
      selectAll: false,
      checkCount: 0,
      currentRequest: null
    };
  },
  props: ["requestsBase", 'accessLevels', 'levelsCheck', 'requestTypeOptions', 'statusType', 'activeTab', 'incTab', 'editAccessStatusList'],
  methods: {
    haveEditAccess(row) {
        return 'status' in row && row.status !== null && this.editAccessStatusList.includes(row.status.status) && this.incTab
    },
    haveDeleteAccess(row) {
        return 'status' in row && row.status !== null && [1].includes(row.status.status)
    },
    checkInVisible() {
        return this.checkCount > 0;
    },
    getFullName(row) {
      if (!this.requestTypeOptions || !this.requestTypeOptions.length) return;
      const curType = this.requestTypeOptions.filter((item) => item.code === row.requestType);
      if (!curType || !curType.length) return;
      const localName = curType[0]["name_" + this.$i18n.locale];
        // eslint-disable-next-line
        // @ts-ignore
      if (row.level === 'abp' && row.localText === undefined) {
          this.$set(row, 'localText', row.abp + ' - abp');
      }
      if (row.level !== 'uf' && this.levelsCheck) {
          return `${localName} ${row.localText}`;
      } else {
          return `${localName}`;
      }

    },
    getLocalStatus(value) {
      const status = value.status.status;
      if (!this.statusType || !this.statusType.length) return status;
      const curStatus = this.statusType.filter((item) => item.code === status);
      if (!curStatus || !curStatus.length) return status;
      const localName = curStatus[0]["name_" + this.$i18n.locale];
      return localName;
    },
    getLink(name) {
      if (!name) return "";
      const extn = name.match(/\.([^.]+)$/)?.[1];
      let fileType = null;
      if (extn && ["doc", "docx"].includes(extn)) fileType = "word";
      if (extn && ["csv", "xls", "xlsx"].includes(extn)) fileType = "excel";
      if (extn && ["pdf"].includes(extn)) fileType = "pdf";
      if (!fileType) return "";
      return `img/svg-icons/${fileType}.svg`;
    },
    getIcon() {
          return `img/svg-icons/merge4.svg`;
      },
    async rowOpen(row) {
      this.onOpen(row);
      },
    onChange(item){
       this.$emit('onChange', item);
    },
    onChecked (e) {
        this.$nextTick(() => {
            if (e) {
                this.checkButton++;
            } else {
                this.checkButton--;
            }
            this.$emit('onChecked', this.checkButton);
            if (this.checkCount === this.checkButton) {
                this.selectAll = true;
            }
        });

    },
    onInput (e) {
        if (!e) this.selectAll = false;
    },
    downloadApp(item) {
      for (const r of this.requestsBase) {
        r.selected = false;
      }
      this.selectedAlteration = item;
      item.selected = !item.selected;
      item.id += 1;
      item.id -= 1;
    },
    setIsAllDelete(val, data = this.requestsBase) {
        if (this.checkButton !== this.checkCount) { this.checkButton = 0; }
      data.forEach(item => {
          if (item.checking) {
              this.$set(item, 'check', val);
              this.onChecked(val);
          }
      });

    },
    async downloadApplication(app) {
      const item = this.requestsBase.find(x => x.selected);
      if (!item) {
        this.makeToast('warning', 'Сообщение', 'Не выбрана форма');
      } else {
        const year = new Date(item.date).getFullYear();
        const d = {
          'app': app,
          'id': item.id,
          'gu': item.gu,
          'year': year,
          'number': item.number,
          'request_type': item.requestType,
          'abp': item.abp,
          'treeData': []
        }
        const that = this;
        Ax(
            {
                url: '/api-py/alteration_application/',
                method: 'POST',
                responseType: 'blob',
                data: d
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                if (app === 1) {
                  link.setAttribute('download', 'application28.xlsx');// or any other extension
                }
                if (app === 2) {
                  link.setAttribute('download', 'application27.xlsx');// or any other extension
                }
                if (app == 3) {
                  link.setAttribute('download', 'application34.xlsx');// or any other extension
                }
                if (app == 4) {
                  link.setAttribute('download', 'application36.xlsx');// or any other extension
                }
                if (app == 5) {
                  link.setAttribute('download', 'application29.xlsx');// or any other extension
                }
                if (app == 6) {
                  link.setAttribute('download', 'application30.xlsx');// or any other extension
                }
                if (app == 7) {
                  link.setAttribute('download', 'application37.xlsx');// or any other extension
                }
                if (app == 8) {
                  link.setAttribute('download', 'application39.xlsx');// or any other extension
                }
                if (app == 9) {
                  link.setAttribute('download', 'application25.xlsx');// or any other extension
                }
                if (app == 10) {
                  link.setAttribute('download', 'application26.xlsx');// or any other extension
                }
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
            }
        )
      }
    },
    async downloadFile(app) {
          try {
              const item = this.requestsBase.find(x => x.selected);
              if (app === 'SVI_IPF_PO') {
                  await axios.get(`api/budget-execution-base/xls?type=OBLIGATION&requestId=${item.id}&planType=EXPENSE&addIpf=false&addAllAgreed=false&code=${app}`, {responseType: 'blob'}).then(response => {
                      this.currentRequest = response.data
                  }).catch(error => {
                      console.log(error)
                      this.currentRequest = null
                  })
              }
              if (app === 'SVI_IPF_PP') {
                  await axios.get(`api/budget-execution-base/xls?type=PAYMENT&requestId=${item.id}&planType=EXPENSE&addIpf=false&addAllAgreed=false&code=${app}`, {responseType: 'blob'}).then(response => {
                      this.currentRequest = response.data
                  }).catch(error => {
                      console.log(error)
                      this.currentRequest = null
                  })
              }
              if (app === 'SVI_SPF_PO') {
                  await axios.get(`api/budget-execution-base/xls?type=OBLIGATION&requestId=${item.id}&level=UF&dataLevel=PRG&planType=EXPENSE&addIpf=false&addAllAgreed=false&code=${app}`, {responseType: 'blob'}).then(response => {
                      this.currentRequest = response.data
                  }).catch(error => {
                      console.log(error)
                      this.currentRequest = null
                  })
              }
              if (app === 'SVI_SPF_PP') {
                  await axios.get(`api/budget-execution-base/xls?type=PAYMENT&requestId=${item.id}&level=UF&dataLevel=PRG&planType=EXPENSE&addIpf=false&addAllAgreed=false&code=${app}`, {responseType: 'blob'}).then(response => {
                      this.currentRequest = response.data
                  }).catch(error => {
                      console.log(error)
                      this.currentRequest = null
                  })
              }
              if (app === 'SVI_SP_PB') {
                  await axios.get(`api/budget-execution-base/xls?requestId=${item.id}&addSpp=false&addAllAgreed=false&planType=INCOME&code=${app}`, {responseType: 'blob'}).then(response => {
                      this.currentRequest = response.data
                  }).catch(error => {
                      console.log(error)
                      this.currentRequest = null
                  })
              }
             if (this.currentRequest !== null) {
                 const url = window.URL.createObjectURL(new Blob([this.currentRequest], {type: 'application/xls'}));
                 const link = document.createElement('a');
                 link.href = url;
                 link.target = '_blank';
                 link.setAttribute('download', `${app}` + '.xls');
                 link.click();
             } else {
                 this.makeToast('danger', 'Предупреждение', 'Заявка пуста!');
             }
          } catch (error) {
              console.error('Произошла ошибка при загруке файла', error.toString());
              this.toast('danger', this.i18n.translate('error.cannot_load_files'), `Заявка не найдена`)
          }
      },
    chgData() {
       this.$emit('chgData', this.requestsBase);
    },
    onOpen(item){
       this.$emit('onOpen', item);
    },
    onDelete(item) {
       this.$emit('onDelete', item);
    },
    async downloadLink(file) {
        const that = this;
        Ax(
            {
                url: '/api/budget-execution-alteration-request/file/' + file.id, //  Тело файла
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
                this.makeToast(
                    "danger",
                    "Ошибка загрузки",
                    // "Приложенные файлы отсутствуют в хранилище"
                    error.toString()
                );
            }
        );
    },
    async delete(item) {
          this.$set(item, 'form', this.form.code);
          try {
              this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_total'));
              const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                  method: 'DELETE',
                  headers: {
                      'Content-Type': 'application/json;charset=utf-8'
                  },
                  body: JSON.stringify(item)
              });
              const result = await response.json();
              if ((response.status === 200) && (result.result === 'success')) {
                  this.makeToast('success', 'Сообщение', 'Запись удалена');
                  // await this.saveTotal();
              }
          } catch {
              this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
          }
      }, // удаление данных
    makeToast(variant, title, tostbody) {
          this.$bvToast.toast(tostbody, {
              title: title,
              variant: variant,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              appendToast: true
          });
      }, // сообщение
    infoModule(info_id, file) {
        const that = this;
        Ax(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                makeToast(this, 'danger', 'Ошибка загрузки', error.toString());
            }
        );
    }
  },
  mounted () {},
  watch: {
        "requestsBase": function (val) {
            this.checkCount = 0;
            val.forEach(item => {
                if (item.checking === true) {
                    this.checkCount += 1;
                }
            });
            this.selectAll = false;
        }
  },
  created () {},
  filters: {
      formatDate: function (value) {
          if (value) {
              const date = new Date(value);
              return date.toLocaleDateString();
          }
          return null;
      }
    }
};
</script>


<style>
.doc-icon {
  margin-right: 5px;
}

.selected_row {
  background-color: #86cfda;
}
</style>